import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Snackbar, IconButton, Typography, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useSwipeable } from 'react-swipeable';

const InAppNotification = () => {
  const [notification, setNotification] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.messageType === "notification-clicked") {
        window.location.href = event.data.notification.data.url;
      } else if (event.data && event.data.messageType === "push-received") {
        setNotification({
          title: event.data.notification.title,
          body: event.data.notification.body,
          url: event.data.notification.data.url,
          action_name: event.data.notification.data.action_name
        });
        setOpen(true);
      }
    });
  } catch (error) {
    console.error('Error adding service worker event listener:', error);
  }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setNotification(null);
  };

  const handleClick = () => {
    if (notification && notification.url) {
      navigate(notification.url);
      setOpen(false);
    }
  };

  const handleActionClick = () => {
    if (notification && notification.url) {
      navigate(notification.url);
      setOpen(false);
    }
  };

  const handleSwipe = (eventData) => {
    if (eventData.dir === 'Left' || eventData.dir === 'Right' || eventData.dir === 'Up') {
      setOpen(false);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
    trackMouse: true
  });

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "down" }}
      sx={{ cursor: 'pointer' }}
      onExited={handleExited}
    >
      <Box
        {...swipeHandlers}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(10px)',
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          padding: 2,
          minWidth: '400px',
          maxWidth: '95%',
          display: 'flex',
          flexDirection: 'column',
          touchAction: 'pan-y',
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Box
            component="img"
            src="/logo.svg"
            alt="App Logo"
            sx={{
              width: 40,
              height: 40,
              borderRadius: '10px',
              mr: 2,
            }}
          />
          <Typography variant="subtitle1" fontWeight="bold" sx={{ flex: 1 }}>
            {notification?.title}
          </Typography>
          <IconButton
            size="small"
            aria-label="close"
            onClick={(e) => {
              e.stopPropagation();
              handleClose(e);
            }}
            sx={{ color: 'text.primary', ml: 1, mt: -1, mr: -1 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography variant="body2">
          {notification?.body}
        </Typography>
        {notification?.action_name && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Button
              variant="text"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleActionClick();
              }}
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: '0.9rem',
              }}
            >
              {notification.action_name}
            </Button>
          </Box>
        )}
      </Box>
    </Snackbar>
  );
};

export default InAppNotification;
