import axios from "axios";

const apiUrl = process.env.REACT_APP_MGO_API_URL

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function getCookieValue(k){var v=document.cookie.match('(^|;) ?'+k+'=([^;]*)(;|$)');return v?v[2]:null}

class AuthService {
  isLogged() {
    return (getCookieValue("jwtToken") !== null &&  getCookieValue("jwtToken") !== "undefined")
  }

  logout() {
    console.log("deleting cookie")
    document.cookie = 'jwtToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.clear( )
    // window.location.href= '/login';
  }

  getCurrentUser() {
    if (this.isLogged() && getCookieValue("jwtToken") !== undefined) {
      return parseJwt(getCookieValue("jwtToken")).name;
    }
    return 'Bienvenido';

  }

  getCurrentUserEmail() {
    if (this.isLogged() && getCookieValue("jwtToken") !== undefined) {
      return parseJwt(getCookieValue("jwtToken")).username;
    }
    return 'Bienvenido';

  }

  getCurrentUnit() {
    if (this.isLogged() && getCookieValue("jwtToken") !== undefined) {
      return parseJwt(getCookieValue("jwtToken")).unit.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    return '';

  }

  userRoleIncludes(roles) {
    roles.push("superuser");

    if (roles.includes(this.getCurrentUserRole())) {
      return true;
    }
    return false;

  }

  getCurrentUserRole() {
    if (this.isLogged() && getCookieValue("jwtToken") !== undefined) {
      return parseJwt(getCookieValue("jwtToken")).role;
    }
    return '';

  }

  getCurrentUserOrg() {
    if (this.isLogged() && getCookieValue("jwtToken") !== undefined) {
      return parseJwt(getCookieValue("jwtToken")).org.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    return 'Inicie Sesion';

  }
}

export default new AuthService();