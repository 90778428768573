import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppConfig from '../services/app-config';
import { Navigate, useLocation } from 'react-router-dom';

import BackgroundImage from '../components/Cityline';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://migarita.online">
        MiGarita.Online
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Register({setNavbarState, navbarState}) {
  const [email, setEmail] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [severity, setSeverity] = React.useState("warning");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const location = useLocation();
  const invcode = new URLSearchParams(location.search).get('invcode');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: false, showOptions: false, showSearch: false})
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}/register/code/info`, {
          code: invcode
        })
        if (response.status === 200) {
          setEmail(response.data.data.email);
          setUserName(response.data.data.name);
        }
      } catch (err) {
        console.log(err);
        setSnackbarOpen(true);
        setSnackbarMessage("El enlance de registro no es valido o ha caducado, solicita uno nuevo a administracion.");
      }
    }
    fetchData();
  }, [invcode, apiUrl]);

  const register = async () => {

    if (email === "") {
      setSnackbarOpen(true);
      setSnackbarMessage("Necesitas un enlance de invitacion valido, solicitalo en administracion,");
      return;
    }

    if (password === "" || confirmPassword === "") {
      setSnackbarOpen(true);
      setSnackbarMessage("Porfavor establece una contraseña.");
      return;
    }

    if (password.length < 8) {
      setSnackbarOpen(true);
      setSnackbarMessage("Porfavor usa al menos 8 caracteres para tu contraseña.");
      return;
    }

    if (password !== confirmPassword) {
      setSnackbarOpen(true);
      setSnackbarMessage("Las contraseñas no son iguales.");
      return;
    }
    setShowBackdrop(true);

    try {
      const res = await axios.post(`${apiUrl}/register`, { username: email, password: password, invitation_code: invcode});
      AppConfig.parseResponse(res);
      if (res.status === 200) {
        setSnackbarOpen(true);
        setSeverity("success");
        setSnackbarMessage("Registo Exitoso =)");
        AppConfig.parseResponse(res);
        setShowBackdrop(false);
        setTimeout(function(){ window.location.href= '/';}, 2000);
      }
    } catch (err) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error al registrarte, intenta de nuevo en otro momento.');
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="sm">
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0 }}>
          <img src="/logo.svg" alt="Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '20%' }} />
          <Typography variant="h4" component="h1" align='center' gutterBottom
            sx={{ fontFamily: 'Roboto', mb: 0 }}>
            Hola!
          </Typography>
          <Typography variant="h6" component="h1" align='center' gutterBottom
            sx={{ fontFamily: 'Roboto' }}>
            {userName}
          </Typography>
        </Box>
        
        <Divider variant="middle" />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0, mt: 4 }}>
          {email === "" && (
            <Typography color="textSecondary" variant="body1" align="center" sx={{ mb: 2 }}>
              Este enlace de registro no es válido o ha vencido, solicita un nuevo enlace en administracion.
            </Typography>
          )}
          
          {email !== "" && (
            <TextField fullWidth size="medium" type='email' id='email' sx={{ mb: 5, mt: 1.2 }} label="Correo Electrónico" variant="outlined" value={email} disabled InputProps={{ readOnly: true }} />
          )}
          {email !== "" && (
          <Typography variant="h6" component="h1" align='left' gutterBottom
            sx={{ fontFamily: 'Roboto' }}>
            Crea una nueva contraseña
          </Typography>
          )}
          <TextField
            fullWidth
            size="medium"
            type="password"
            id="password"
            sx={{ mb: 1.8 }}
            label="Contraseña"
            helperText="* Al menos 8 caracteres."
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={email === ""}
          />
          <TextField
            fullWidth
            size="medium"
            type="password"
            id="confirmPassword"
            sx={{ mb: 5 }}
            label="Confirmar Contraseña"
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={email === ""}
          />
          <Button fullWidth variant="contained" color="secondary" onClick={register} sx={{ py: 1.4 }} disabled={email === ""}>
            Registrarme
          </Button>
        </Box>
        </Box>
        <Box sx={{ position: 'fixed', bottom: 0, width: '100%' }}>
          <Copyright />
        </Box>
        <BackgroundImage />
        </Container>
        );
        }
