import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { TypeAnimation } from 'react-type-animation';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';


import { TextField, Button, Box, Input } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ClearIcon from '@mui/icons-material/Clear';
import BackgroundImage from '../components/Cityline';

import Resizer from 'react-image-file-resizer';

import AuthService from '../services/auth';
import AppConfig from '../services/app-config';

import { Navigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';

function FormCard({ setNavbarState, navbarState}) {
    const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
    const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
    const [area, setArea] = useState('');
    const [descripcionBreve, setDescripcionBreve] = useState('');
    const [descripcionDetallada, setDescripcionDetallada] = useState('');
    const [image, setImage] = useState(null);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const [categories, setCategories] = React.useState([]);
    const [formConfig,setFormConfig] = React.useState({ org: "vidali", language: "es", languages : {"es": "Español", "en": "Inglés","pt": "Portugués", "fr": "Francés","ger": "Aleman"}});

    const apiUrl = process.env.REACT_APP_MGO_API_URL;

    const userCors = process.env.REACT_APP_USE_CORS;
    if ( userCors === "true") {
        axios.defaults.withCredentials = true
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbar({ ...snackbar, open: false });
      };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file.size > 1024 * 1024) {
            Resizer.imageFileResizer(
            file,
            800,
            800,
            'JPEG',
            80,
            0,
            (compressedFile) => {
                setImage(compressedFile);
                setShowBackdrop(false);
            },
            'blob' // Output type
            );
        } else {
            if (file.type.startsWith('image/')) {
            setImage(file);
            setShowBackdrop(false);
            } else {
            setShowBackdrop(false);
            setSnackbar({ open: true, message: 'Por favor, solo utilice imagenes', severity: 'error' });
            setImage(null);
            }
        }
    };

    const welcomeCard = (
        <Box sx={{ textAlign: 'center', alignItems: 'center', mb: 4 }}>
            <TypeAnimation
                sequence={[
                    // Same substring at the start will only be typed once, initially
                    'Bienvenido a',
                    2000,
                    'Welcome to',
                    2000,
                    'Bienvenue à',
                    1500,
                    'Benvenuto a',
                    1500,
                    'Willkommen bei',
                    1500,
                    
                ]}
                speed={50}
                style={{ fontSize: '2em' }}
                repeat={Infinity}
                />
            <Typography variant="h3" color="textPrimary" textTransform={ 'uppercase'}>
                {formConfig.org}
            </Typography>
            <Box
                  component="img"
                  src={`/orgs/${formConfig.org.toLowerCase()}.png`}
                  alt={`${formConfig.org}-logo`}
                  sx={{
                    width: '60px',
                    height: '60px',
                    objectFit: 'contain',
                  }}
                />
            <Card sx={{ mt: 4, px: 4}} variant="outlined">
                <CardContent>
                    <Typography variant="h5" color="textSecondary">
                        Selecciona un Idioma
                    </Typography>
                    <FormControl fullWidth variant="outlined" sx={{mt: 2}}>
                        <InputLabel id="language-select-label">Idioma</InputLabel>
                        <Select
                            labelId="language-select-label"
                            id="language-select"
                            label="Idioma"
                        >
                            {Object.entries(formConfig.languages).map(([value, label], index) => (
                              <MenuItem key={index} value={value}>
                                {AppConfig.titleCase(label)}
                              </MenuItem>
                            ))}

                            
                        </Select>
                    </FormControl>
                </CardContent>
            </Card>
        </Box>   
    )


    const handleSubmit = (e) => {
        e.preventDefault();

        if (area.trim() === '' || descripcionBreve.trim() === '' || descripcionDetallada.trim() === '') {
            setSnackbar({ open: true, message: 'Todos los campos son requeridos', severity: 'error' });
            return;
        }

        setShowBackdrop(true);
        const formData = new FormData();
        formData.append('area', area);
        formData.append('descripcionBreve', descripcionBreve);
        formData.append('descripcionDetallada', descripcionDetallada);
        if (image) {
            formData.append('image', image);
        }
        axios.post(`${apiUrl}/issue/create`, formData)
            .then((response) => {
                AppConfig.parseResponse(response);
                if (response.status === 200) {
                    setRedirect({ trigger: true, route: '/reportes' });
                  } else if (response.status === 422) {
                    setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
                  }
                setShowBackdrop(false);
            })
            .catch((error) => {
                if(error.response && error.response.data && error.response.data.code === 422) {
                setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
                }
                else  {
                console.error('Error commenting:', error);
                setSnackbar({ open: true, message: "Hubo un error al intentar agregar tu reporte, porfavor intente mas tarde.", severity: 'error' })};
                setShowBackdrop(false);
            });
    };

    React.useEffect(() => {
        setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false});
    },[]);

    return (
        <Container maxWidth="sm">

       
        <Box>
            {redirect.trigger && (
                <Navigate to={redirect.route} replace={true} />
            )}
            <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                {snackbar.message}
                </Alert>
            </Snackbar>
            <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box
                sx={{ mt: 2 ,py: 0.5, px: 0.5, display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: "550px" }}
            >
                {welcomeCard}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 6,  py: 0.5, px: 0.5 }}>
                    <Button variant="contained" fullWidth color="secondary" size="large" onClick={handleSubmit}>Siguiente</Button>
                    {/* <Button variant="text" color="primary" size="large" sx={{mt : 1.5}} onClick={() => setRedirect({ trigger: true, route: '/reportes' })}>Cancelar</Button> */}
                </Box>
                
            </Box>
            <BackgroundImage />
        </Box>
        </Container>
    );
}

export default FormCard;
