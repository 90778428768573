import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import BackgroundImage from '../components/Cityline';
import AuthService from '../services/auth';
import AppConfig from '../services/app-config';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';

// Import icons
import PeopleIcon from '@mui/icons-material/People';
import ChairIcon from '@mui/icons-material/Chair';
import EventIcon from '@mui/icons-material/Event';
import SecurityIcon from '@mui/icons-material/Security';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';

import CircularProgress from '@mui/material/CircularProgress';
import ReactAnimatedWeather from 'react-animated-weather';
import axios from 'axios';
import styled from '@mui/material/styles/styled';

export default function AdminHub({ setNavbarState, navbarState }) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [org, setOrg] = React.useState("");
  const [weather, setWeather] = React.useState({ temp: "", icon: 'NONE', fetched: false });
  const [status, setStatus] = React.useState({
    visitors: 0,
    pending_issues: 0,
    pending_approvals: 0,
    activity_entries: 0,
  });

  const apiUrl = process.env.REACT_APP_MGO_API_URL;

  const userCors = process.env.REACT_APP_USE_CORS;
  if (userCors === "true") {
    axios.defaults.withCredentials = true;
  }

  const getWeather = async () => {
    try {
      const response = await axios.get(`${apiUrl}/weather/current`);
      setWeather({ temp: response.data.data.temp, icon: response.data.data.icon, fetched: true });
    } catch (error) {
      console.error(error);
    }
  };

  const getStatus = async () => {
    try {
      const response = await axios.get(`${apiUrl}/admin/status/${AuthService.getCurrentUserOrg().toLowerCase()}`);
      setStatus(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    setNavbarState({ ...navbarState, showBack: false, showOptions: false, showSearch: false });
    setOrg(AuthService.getCurrentUserOrg());
    getWeather();
    getStatus();
  }, []);

  const handleClick = (route) => {
    setRedirect({ trigger: true, route: `/${route.toLowerCase()}` });
  };

  const cardStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    },
  };

  const iconStyle = {
    position: 'absolute',
    right: -20,
    bottom: -20,
    fontSize: 105,
    color: 'rgba(0, 0, 0, 0.10)',
  };

  const SuperC = styled('sup')({
    fontSize: '0.6em',
    verticalAlign: 'super',
  });

  const badgeStyle = {
    '& .MuiBadge-badge': {
      right: 15,
      top: 15,
      padding: '0 4px',
      height: '22px',
      minWidth: '22px',
    }
  };

  const highlightText = {
    color: 'primary.main',
    fontWeight: 'bold',
  };

  const highlightTextCritical = {
    color: 'error.main',
    fontWeight: 'bold',
  };

  if (!AuthService.userRoleIncludes(["admin"])) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <Container maxWidth="sm">
      {redirect.trigger && <Navigate to={redirect.route} replace={true} />}
      <Box sx={{ mt: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: -3 }}>
            <Card sx={{ ...cardStyle, bgcolor: 'white', position: 'relative', boxShadow: 0, pb: 3 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
                    {org}
                  </Typography>
                  <Box sx={{ display: !weather.fetched ? "flex" : "none", ml: 2, mt: 1 }}>
                    <CircularProgress size={26} />
                  </Box>
                  <Box sx={{ display: weather.fetched ? "flex" : "none", alignItems: 'center', ml: 2, mt: 1 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', mr: 1 }}>
                      {weather.temp}<SuperC>o</SuperC>C
                    </Typography>
                    <ReactAnimatedWeather
                      icon={weather.icon}
                      color='black'
                      size={34}
                      animate={true}
                    />
                  </Box>
                </Box>
                <Box
                  component="img"
                  src={`/orgs/${org.toLowerCase()}.png`}
                  alt={`${org}-logo`}
                  sx={{
                    width: '60px',
                    height: '60px',
                    objectFit: 'contain',
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={() => handleClick('useradmin')}>
              <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Usuarios
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Gestionar usuarios
                </Typography>
              </CardContent>
              <PeopleIcon sx={iconStyle} />
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ display: AppConfig.haveModule('visits') ? "block" : "none" }}>
            <Badge badgeContent={status.visitors} color="info" max={99} sx={{ ...badgeStyle, width: '100%' }}>
              <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative', width: '100%' }} onClick={() => handleClick('recepcion')}>
                <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                  <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Recepción
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Gestionar visitas
                  </Typography>
                  {status.visitors > 0 && (
                    <Typography variant="body2" sx={highlightText}>
                      {status.visitors} {status.visitors === 1 ? 'visita esperada' : 'visitas esperadas'}
                    </Typography>
                  )}
                </CardContent>
                <SecurityIcon sx={iconStyle} />
              </Card>
            </Badge>
          </Grid>
          <Grid item xs={12} sx={{ display: AppConfig.haveModule('issues') ? "block" : "none" }}>
            <Badge badgeContent={status.pending_issues} color="error" max={99} sx={{ ...badgeStyle, width: '100%' }}>
              <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative', width: '100%' }} onClick={() => handleClick('reportes')}>
                <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                  <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Reportes de Averias
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ver y gestionar reportes de problemas en {org}
                  </Typography>
                  {status.pending_issues > 0 && (
                    <Typography variant="body2" sx={highlightTextCritical}>
                      {status.pending_issues} {status.pending_issues === 1 ? 'reporte pendiente' : 'reportes pendientes'} de revisar
                    </Typography>
                  )}
                </CardContent>
                <ListAltIcon sx={iconStyle} />
              </Card>
            </Badge>
          </Grid>
          <Grid item xs={12} sx={{ display: AppConfig.haveModule('amenities') ? "block" : "none" }}>
            <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={() => handleClick('amenidades')}>
              <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Amenidades
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Ver amenidades
                </Typography>
              </CardContent>
              <ChairIcon sx={iconStyle} />
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ display: AppConfig.haveModule('amenities') ? "block" : "none" }}>
            <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={() => handleClick('amenityadmin')}>
              <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Administrar Amenidades
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Configurar amenidades
                </Typography>
              </CardContent>
              <ListAltIcon sx={iconStyle} />
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ display: AppConfig.haveModule('amenities') ? "block" : "none" }}>
            <Badge badgeContent={status.pending_approvals} color="info" max={99} sx={{ ...badgeStyle, width: '100%' }}>
              <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative', width: '100%' }} onClick={() => handleClick('reservaciones')}>
                <CardContent sx={{ position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    Reservaciones
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    Ver todas las reservaciones
                  </Typography>
                  {status.pending_approvals > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 'auto' }}>
                      <Typography variant="body2" sx={{ ...highlightText, fontSize: '0.75rem' }}>
                        {status.pending_approvals} {status.pending_approvals === 1 ? 'pendiente de aprobación' : 'pendientes de aprobación'}
                      </Typography>
                      <Button 
                        variant="text" 
                        color="primary" 
                        size="small" 
                        sx={{ p: 0.5, minWidth: 0 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick('aprobaciones');
                        }}
                      >
                        Revisar
                      </Button>
                    </Box>
                  )}
                </CardContent>
                <EventIcon sx={iconStyle} />
              </Card>
            </Badge>
          </Grid>
          <Grid item xs={12}>
            {/* <Badge badgeContent={status.activity_entries} color="info" max={999} sx={{ ...badgeStyle, width: '100%' }}> */}
              <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative', width: '100%' }} onClick={() => handleClick('actividad')}>
                <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                  <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Actividad
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ver historial de actividad
                  </Typography>
                  {status.activity_entries > 0 && (
                    <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                      {status.activity_entries} {status.activity_entries === 1 ? 'registro el dia de hoy' : 'registros el dia de hoy'}
                    </Typography>
                  )}
                </CardContent>
                <HistoryIcon sx={iconStyle} />
              </Card>
            {/* </Badge> */}
          </Grid>
        </Grid>
      </Box>
      <BackgroundImage />
    </Container>
  );
}