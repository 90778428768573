import * as React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../services/auth.js';

import BackgroundImage from '../components/Cityline';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { IconButton, Button } from "@mui/material";
import Typography from '@mui/material/Typography';

import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import CameraIcon from '@mui/icons-material/Camera';
import AppConfig from '../services/app-config';



export default function Camera(props) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [imageDataURL, setImageDataURL] = React.useState(null);
//   const [cameraNumber, setCameraNumber] = React.useState(0);
  
  React.useEffect(() => {
    initializeMedia(true);

  }, []);

  let player = null;
  let cameraNumber = 0

  const initializeMedia = async (initial=false) => {
    setImageDataURL(null);
    if (!("mediaDevices" in navigator)) {
      navigator.mediaDevices = {};
    }

    if (!("getUserMedia" in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
            return Promise.reject(new Error("getUserMedia Not Implemented"));
        }

        return new Promise((resolve, reject) => {
            getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }

    //Get the details of video inputs of the device
    const videoInputs = await getListOfVideoInputs();
    //The device has a camera
    if (videoInputs.length) {
        if (videoInputs.length > 1 && initial) {
            cameraNumber = 1;
        }
        navigator.mediaDevices
            .getUserMedia({
                video: {
                    deviceId: {
                        exact: videoInputs[cameraNumber].deviceId,
                    },
                },
            })
            .then((stream) => {
                player.srcObject = stream;
            })
            .catch((error) => {
                if (error.name === "OverconstrainedError") {
                    console.log("Video constraints cannot be met by the device");
                } else {
                    console.error(error);
                }
            });
    } else {
        alert("The device does not have a camera");
    }
  };

  const capturePicture = () => {
    var canvas = document.createElement("canvas");
    canvas.width = player.videoWidth;
    canvas.height = player.videoHeight;
    var contex = canvas.getContext("2d");
    contex.drawImage(player, 0, 0, canvas.width, canvas.height);
    player.srcObject.getVideoTracks().forEach((track) => {
      track.stop();
    });

    console.log(canvas.toDataURL());
    setImageDataURL(canvas.toDataURL());
  };

  const switchCamera = async () => {
    const listOfVideoInputs = await getListOfVideoInputs();

    // The device has more than one camera
    if (listOfVideoInputs.length > 1) {
      if (player.srcObject) {
        player.srcObject.getVideoTracks().forEach((track) => {
          track.stop();
        });
      }

      // switch to second camera
      if (cameraNumber === 0) {
        cameraNumber = 1;
      }
      // switch to first camera
      else if (cameraNumber === 1) {
        cameraNumber = 0;
      }

      // Restart based on camera input
      initializeMedia();
    } else if (listOfVideoInputs.length === 1) {
      alert("The device has only one camera");
    } else {
      alert("The device does not have a camera");
    }
  };

  const getListOfVideoInputs = async () => {
    // Get the details of audio and video output of the device
    await navigator.mediaDevices.getUserMedia({audio: true, video: true});
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

    //Filter video outputs (for devices with multiple cameras)
    return enumerateDevices.filter((device) => device.kind === "videoinput");
  };

    const imagePlaceHolder = (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                mt: 2,
            }}
        >
        {imageDataURL ? (<img src={imageDataURL} alt="cameraPic" style={{ maxWidth: "100%", borderRadius: "15px" }} />) : (
        <video
            ref={(refrence) => {
                player = refrence;
            }}
            autoPlay
            style={{ maxWidth: "100%" , borderRadius: "15px"}}
        ></video>)}
        </Box>
        
    );


    return (
        <Container maxWidth="sm" >
        {redirect.trigger && (
            <Navigate to={redirect.route} replace={true} />
        )}

        {imagePlaceHolder}
        <Box sx={{ display: "flex", justifyContent: "center", position: "fixed", bottom: "5rem", width: "100%" }}>
          <IconButton
            aria-label="switch camera"
            onClick={capturePicture}
          >
            <CameraIcon style={{ fontSize: "48px" }} />
          </IconButton>
          <IconButton
            aria-label="switch camera"
            onClick={switchCamera}
          >
            <FlipCameraIosIcon />
          </IconButton>
          
        </Box>
      
        </Container>
    );
}