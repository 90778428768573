import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import BackgroundImage from '../components/Cityline';
import AppConfig from '../services/app-config';
import {
  Container, Box, Card, CardContent, Typography, Button,
  CircularProgress, Snackbar, Alert, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import AuthService from '../services/auth.js';
import Divider from '@mui/material/Divider';
import PhoneIcon from '@mui/icons-material/Phone';
import LocalParkingIcon from '@mui/icons-material/LocalParking';

export default function ParkRequest({ setNavbarState, navbarState }) {
  const location = useLocation();
  const requestId = new URLSearchParams(location.search).get('id');
  const [request, setRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState({ trigger: false, route: '/' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [mySharingState, setMySharingState] = useState({});
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const apiUrl = process.env.REACT_APP_MGO_API_URL;

  const iconStyle = {
    position: 'absolute',
    right: -60,
    bottom: -60,
    fontSize: 305,
    color: 'rgba(0, 0, 0, 0.10)',
  };

  useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false});
    if (requestId) {
      fetchRequestDetails();
      fetchMySharingState();
    } else {
      setRedirect({ trigger: true, route: '/parqueos' });
    }
  }, [requestId]);

  const fetchRequestDetails = () => {
    axios.get(`${apiUrl}/parksharing/request/${requestId}`)
      .then(response => {
        AppConfig.parseResponse(response);
        response.data.data['undergoing'] = AppConfig.isCurrentDateInRange(response.data.data['time_start'], response.data.data['time_end']);
        setRequest(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching request details:', error);
        if (error.response && error.response.data && error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' });
        } else if (error.response && error.response.data && error.response.data.code === 404) {
          setRedirect({ trigger: true, route: '/parqueos' });
        }
      });
  };

  const fetchMySharingState = () => {
    axios.get(`${apiUrl}/parksharing/status`)
      .then(response => {
        AppConfig.parseResponse(response);
        setMySharingState(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching parking sharing state:', error);
        if (error.response && error.response.data && error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' })
        }
      });
  }

  const handleAccept = () => {
    axios.post(`${apiUrl}/parksharing/approve`, {hash_id: requestId})
      .then(response => {
        AppConfig.parseResponse(response);
        setSnackbar({ open: true, message: "Solicitud aceptada exitosamente", severity: "success" });
        fetchRequestDetails();
      })
      .catch(error => {
        console.error('Error accepting request:', error);
        setSnackbar({ open: true, message: "Error al aceptar la solicitud", severity: "error" });
      });
  };

  const handleCancel = () => {
    axios.post(`${apiUrl}/parksharing/cancel`, {hash_id: requestId})
      .then(response => {
        AppConfig.parseResponse(response);
        setRedirect({ trigger: true, route: '/parqueos' });
      })
      .catch(error => {
        console.error('Error rejecting request:', error);
        setSnackbar({ open: true, message: "Error al rechazar la solicitud", severity: "error" });
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenAcceptDialog = () => {
    setOpenAcceptDialog(true);
  };

  const handleCloseAcceptDialog = () => {
    setOpenAcceptDialog(false);
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleConfirmAccept = () => {
    handleCloseAcceptDialog();
    handleAccept();
  };

  const handleConfirmCancel = () => {
    handleCloseCancelDialog();
    handleCancel();
  };

  if (redirect.trigger) {
    return <Navigate to={redirect.route} replace={true} />;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ height: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', py: 2 }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Card variant="outlined" sx={{ py: 8, width: '100%', overflow: 'hidden', position: 'relative' }}>
          <CardContent sx={{ position: 'relative', zIndex: 1 }}>
            {request.status === "Confirmada" && AuthService.getCurrentUserEmail() === request.covered_by && (
              <>
                <Typography variant="h5" component="div" gutterBottom sx={{ mb: 0 }}>
                  <b>Has Aceptado la Solicitud</b>
                </Typography>
                <Typography variant="h5" component="div" gutterBottom sx={{ mb: 2 }}>
                  ¡Muchas Gracias!
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                  Contacto del vecino: 
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon style={{ fontSize: '1.4rem', verticalAlign: 'middle' }} sx={{ mr: 1 }} /> {request.requester_name}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                  <HomeIcon style={{ fontSize: '1.4rem', verticalAlign: 'middle' }} sx={{ mr: 1 }} /> {request.requester_unit}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
                  <PhoneIcon style={{ fontSize: '1.4rem', verticalAlign: 'middle' }} sx={{ mr: 1 }} /> {request.requester_phone}
                </Typography>
              </>
            )}
            <Typography variant={request.status === "Pendiente" ? "h5" : "h6"} component="div" gutterBottom sx={{ mb: 4 }}>
              {request.status === "Pendiente" ? (
                <b>Detalles de la Solicitud</b>
              ) : (
                <b>Solicitud Confirmada</b>
              )}
            </Typography>
            
            {AuthService.getCurrentUserEmail() !== request.covered_by && (
              <>
              <Typography variant="h6" color="text.secondary">
              <ArrowCircleRightIcon style={{ fontSize: '1.4rem', verticalAlign: 'middle' }} /> <b>Estado:</b> {request.status}
              </Typography>
              <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
                <PersonIcon style={{ fontSize: '1.4rem', verticalAlign: 'middle' }} /> <b>Solicitante:</b> {AppConfig.titleCase(request.requester_name)}
              </Typography>
              </>
            )}
            
            <Typography variant="body1" color="text.secondary">
              <AccessTimeIcon style={{ fontSize: '1.4rem', verticalAlign: 'middle' }} /> <b>Inicio:</b> {AppConfig.formatFriendlyDate(request.time_start)}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              <AccessTimeIcon style={{ fontSize: '1.4rem', verticalAlign: 'middle' }} /> <b>Fin:</b> {AppConfig.formatFriendlyDate(request.time_end)}
            </Typography>
            {
              request.created_by === AuthService.getCurrentUserEmail() && request.status === "Confirmada" && (
                <>
                <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                  Datos del Parqueo
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <LocalParkingIcon style={{ fontSize: '1.4rem', verticalAlign: 'middle' }} sx={{ mr: 1 }} /> {request.parking}
                </Typography>
                {request.indications && (
                <>
                <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', fontWeight: 'bold' }}>
                  Indicaciones:
                </Typography>
                <Typography variant="body1" color="text.secondary">
                {request.indications}
                </Typography>
                    </>
                )}
                {request.image_included && (
                <>
                <Button variant="contained" color="secondary" fullWidth sx={{ mt: 1 }} onClick={() => setRedirect({ trigger: true, route: `/imagenparqueo?hash_id=${requestId}` })}>
                  Ver Detalles
                </Button>
                </>
                )}
                </>
              )
            }
            {
              request.created_by !== AuthService.getCurrentUserEmail() && request.covered_by !== AuthService.getCurrentUserEmail() && request.status === "Confirmada" && (
                <>
                <Typography variant="h5" sx={{ mt: 4, mb: 0}}>
                  Alguien mas acepto esta solicitud
                </Typography>
                <Typography variant="body1" sx={{ mb: 1}}>
                  Muchas gracias por intentar ayudar a tu vecino.
                </Typography>
              </>
            )
            }
          </CardContent>
          {request.status === 'Confirmada' ? <NoCrashIcon sx={iconStyle} /> : <CarCrashIcon sx={iconStyle} />}
        </Card>
      </Box>
      {request.status === 'Pendiente' && (
        <>
          <Box sx={{ width: '100%', mt: 2 }}>
            {!mySharingState.parking && request.created_by !== AuthService.getCurrentUserEmail() && (
              <>
                <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', mb: 1 }}>
                  Tienes que ingresar los datos de tu parqueo antes de poder aceptar una solicitud.
                </Typography>
              <Button variant="text" color="primary" onClick={() => setRedirect({ trigger: true, route: '/onboardingparqueo' })} fullWidth sx={{mb: 2}}>
                  Ingresar datos
                </Button>
              </>
            )}
            {request.created_by !== AuthService.getCurrentUserEmail() ? (
              <>
                {!request.can_i_take_it && mySharingState.parking && <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mb: 2 }}>
                  No puedes aceptar la solicitud porque ya tienes una solicitud aceptada en este horario.
                </Typography>}
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={handleOpenAcceptDialog} 
                  fullWidth 
                  disabled={!mySharingState.parking || !request.can_i_take_it}
                >
                  Aceptar
                </Button>
              </>
            ) : (
              <>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleOpenCancelDialog} 
                  fullWidth
                >
                  Cancelar Mi Solicitud
                </Button>
              </>
            )}
          </Box>
        </>
      )}
      {request.status === 'Confirmada' && (
        <>
          <Box sx={{ width: '100%', mt: 2 }}>
            {request.created_by === AuthService.getCurrentUserEmail() && (
              <>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleOpenCancelDialog} 
                fullWidth
              >
                Cancelar Mi Solicitud
              </Button>
            </>
            )}
            {request.covered_by === AuthService.getCurrentUserEmail() && (
              <>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleOpenCancelDialog} 
                fullWidth
              >
                Cancelar Solicitud
              </Button>
            </>
            )}
          </Box>
        </>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openAcceptDialog}
        onClose={handleCloseAcceptDialog}
        aria-labelledby="accept-dialog-title"
        aria-describedby="accept-dialog-description"
      >
        <DialogTitle id="accept-dialog-title">{"Confirmar Aceptación"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ textAlign: 'center', mb: 1 }}>
            - Si aceptas la solicitud, el vecino podrá acceder a tu parqueo en el horario indicado.
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', mb: 1 }}>
            - Se compartirá el contacto del solicitante en caso necesites comunicarte con él.
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            <b>Inicio:</b> {AppConfig.formatFriendlyDate(request.time_start)}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            <b>Fin:</b> {AppConfig.formatFriendlyDate(request.time_end)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAcceptDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmAccept} color="secondary" autoFocus variant='text' sx={{ px: 4 }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancelDialog}
        onClose={handleCloseCancelDialog}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">{"Confirmar Cancelación"}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ textAlign: 'center', mb: 1 }}>
            ¿Estás seguro de que deseas cancelar esta solicitud de parqueo?
          </Typography>
          {request.covered_by === AuthService.getCurrentUserEmail() && (
            <>
            <Typography variant="body2" sx={{ textAlign: 'center', mb: 1, fontWeight: 'bold' }}>
              {request.requester_name} será notificado de que has cancelado la solicitud.
            </Typography>
            </>
          )}
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2" sx={{ textAlign: 'center', mb: 1 }}>
            Esta acción no se puede deshacer.
          </Typography>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog} color="primary">
            Volver
          </Button>
          <Button onClick={handleConfirmCancel} color="error" autoFocus variant='outlined' sx={{ px: 4 }}>
            Cancelar Solicitud
          </Button>
        </DialogActions>
      </Dialog>
      <BackgroundImage />
    </Container>
  );
}