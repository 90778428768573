import * as React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../services/auth.js';
import AppConfig from '../services/app-config.js';

import axios from 'axios';

export default function Home(props) {
  const [navigateTo, setNavigateTo] = React.useState(null);
  const nextUrl = new URLSearchParams(location.search).get('next');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }
  const apiUrl = process.env.REACT_APP_MGO_API_URL
  
  React.useEffect(() => {
    const role = AuthService.getCurrentUserRole();
    if (nextUrl !== null) {
      setNavigateTo(nextUrl);
      return;
    } else {
    switch (role) {
      case 'habitante':
        setNavigateTo('/residenthub?event=app_opened');
        break;
      case 'receptor':
        setNavigateTo('/recepcion');
        break;
      case 'admin':
        setNavigateTo('/adminhub');
        break;
      case 'superuser':
        setNavigateTo('/adminhub');
        break;
      default:
        setNavigateTo('/login');
    }}

    if (AuthService.isLogged()) {
      axios.get(`${apiUrl}/users/configs`)
      .then((response) => {
          AppConfig.parseResponse(response);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.code === 401) {
          setNavigateTo('/login');
        }
      })  
    } else {
      setNavigateTo('/login');
    }
          
    
  }, []);

  if (navigateTo) {
    return <Navigate to={navigateTo} />
  }

  // default render
  return null;
}