import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../services/auth.js';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fab from '@mui/material/Fab';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import axios from 'axios';
import AppConfig from '../services/app-config';
import { CardHeader } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaymentsIcon from '@mui/icons-material/Payments';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ChairIcon from '@mui/icons-material/Chair';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion';

export default function Amenities({setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [amenities, setAmenities] = React.useState([]);
  const [reservations, setReservations] = React.useState([]);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [tabValue, setTabValue] = React.useState('amenities'); // ['amenities', 'reservations']
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [settings,setSettings] = React.useState({});
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const [swipeDirection, setSwipeDirection] = React.useState(null);
  
  let snackbarmsg = new URLSearchParams(location.search).get('msg');
  let snackbarlvl = new URLSearchParams(location.search).get('lvl',"success");
  let tab = new URLSearchParams(location.search).get('tab');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const handleTabChange = (event,newValue) => {
    setTabValue(newValue);
    fetchInfo(newValue);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  function convertEpochsToTimeString(epoch1, epoch2) {
    const date1 = new Date(epoch1 * 1000);
    const date2 = new Date(epoch2 * 1000);

    const day = date1.getDate();
    const month = date1.toLocaleString('default', { month: 'short' });
    const time1 = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const time2 = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${day}/${month} ${time1} - ${time2}`;
  }

  const fetchInfo = (CurrentTabValue) => {
    setShowBackdrop(true);
    setLoading(true);
    
    const dayOfWeek = AppConfig.getDayOfWeek();
    axios.get(`${apiUrl}/${CurrentTabValue}/list`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          if (CurrentTabValue === 'amenities') {
          const dataArray = Object.entries(response.data.data).map(([id, value]) => ({ id, ...value }));
          const modifiedAmenities = dataArray.map(amenity => {
            if (amenity.bloquear.length !== 0) {
              if (AppConfig.isCurrentDateInRange(amenity.bloquear.fecha_inicio, amenity.bloquear.fecha_fin)) {
                return {
                  ...amenity,
                  disponibilidad: "No Disponible",
                  bloqueado: true
                };
              }
            }
            var updatedHorarios = "No Disponible";
            for (let i = 0; i < amenity.horarios.length; i++) {
              if (amenity.horarios[i].dias.includes(dayOfWeek)) {
                updatedHorarios = `${amenity.horarios[i].hora_inicio} - ${amenity.horarios[i].hora_fin}`;
                break;
              }
            }
            return {
              ...amenity,
              disponibilidad: updatedHorarios
            };
          });
          let showTab = AuthService.userRoleIncludes(["habitante"]);
          setSettings({showTab: showTab});
          setAmenities(modifiedAmenities);
          AppConfig.storeLocal('amenities', JSON.stringify(modifiedAmenities));
          if (snackbarmsg !== null) {
            setSnackbar({ open: true, message: snackbarmsg, severity: snackbarlvl });
            setRedirect({ trigger: true, route: '/amenidades' });
            snackbarmsg = null;
          }
          }
          else if (CurrentTabValue === 'reservations') {
            console.log(response.data.data);
            const dataArray = Object.entries(response.data.data)
              .map(([id, value]) => {
                const extractedId = id.split('-')[0];
                const amenity = amenities.find(amenity => amenity.id === extractedId);
                return {
                  ...value,
                  "rango": convertEpochsToTimeString(value.inicio, value.fin),
                  
                  id,
                  amenityId: extractedId
                };
              })
              .filter(reservation => reservation.amenityId) // Filter out reservations with null amenityId
              .sort((a, b) => a.inicio - b.inicio);
            setReservations(dataArray);
          }
          setShowBackdrop(false);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching amenities:', error);
        setShowBackdrop(false);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    if (tab !== null) { 
      // setRedirect({ trigger: true, route: '/amenidades' });
      setTabValue(tab);
    }
    fetchInfo(tabValue);
  }, [tabValue]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (tabValue === 'amenities' && settings.showTab) {
        setSwipeDirection('left');
        setTabValue('reservations');
      }
    },
    onSwipedRight: () => {
      if (tabValue === 'reservations' && settings.showTab) {
        setSwipeDirection('right');
        setTabValue('amenities');
      }

    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const pageVariants = {
    enter: (direction) => {
      return {
        x: direction === 'left' ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        x: direction === 'left' ? -1000 : 1000,
        opacity: 0
      };
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

return (
    <Container maxWidth="sm" {...swipeHandlers}>
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
    <Box sx={{ mt: 1 }}>
      <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
          {settings.showTab && (<Tabs value={tabValue } onChange={handleTabChange} aria-label="user tabs" variant="fullWidth" sx={{ mt: 0, mb: 0.2 }} >
            <Tab label="Amenidades" value={'amenities'}/>
            <Tab label="Mis Reservaciones" value={'reservations'}/>
          </Tabs>)}
        <AnimatePresence initial={false} custom={swipeDirection}>
          <motion.div
            key={tabValue}
            custom={swipeDirection}
            variants={pageVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={pageTransition}
          >
            {tabValue === "reservations" ? (
              <>
                {!loading && (
                  reservations.length === 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 150px)' }}>
                      <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
                        <CardContent>
                          <EventBusyIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
                          <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No tienes ninguna reservacion vigente</b></Typography>
                          <Divider />
                          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Ve a amenidades y reserva la que desees.</Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  ) : (
                    reservations.map((reservation) => (
                      <Card
                        key={reservation.id}
                        variant="outlined"
                        sx={{ mb: 1.2 }}
                        onClick={() => setRedirect({route: `/reservacion?id=${reservation.id}`, trigger: true})}
                      >
                        <CardHeader
                          className="card-header"
                          style={{
                            backgroundImage: `url(${apiUrl}/img/amenities/${reservation.amenityId}.jpg)`,
                          }}
                        />
                        <CardContent sx={{ pb: 0 }}>
                          <Typography variant="h5" component="div">
                            <b>{reservation.nombre_amenidad}</b>
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {reservation.rango}
                          </Typography>
                          <>
                          {reservation.confirmada ? (
                            <Typography variant="body1" color="text.secondary">
                                <CheckCircleOutlineIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> Confirmada
                            </Typography>
                          ) : (
                            <Typography variant="body1" color="text.secondary">
                             <EventBusyIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> Pendiente de Confirmacion
                            </Typography>
                          )}
                          </>
                          
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end', pr: 1.5, pb: 1.5 }}>
                          <Button
                            color="secondary"
                            variant="contained"
                            size="medium"
                            onClick={() => setRedirect({route: `/reservacion?id=${reservation.id}`, trigger: true})}
                          >
                            Mas Detalles
                          </Button>
                        </CardActions>
                      </Card>
                    )) 
                  )
                )}
              </>
            ) : (
              <>
                {!loading && (
                  amenities.length === 0 ? (

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 150px)' }}>
                    <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
                      <CardContent>
                        <ChairIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
                        <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay amenidades disponibles</b></Typography>
                        <Divider />
                        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Intenta mas tarde o contacta al administrador.</Typography>
                      </CardContent>
                    </Card>
                  </Box>
                  ) : (
                    amenities.filter(amenity => amenity.habilitada).map((amenity) => (
                      <Card
                        key={amenity.id}
                        variant="outlined"
                        sx={{ mb: 1.2 }}
                        onClick={() => setRedirect({ trigger: true, route: `/amenidad?id=${amenity.id}` })}
                      >
                        <CardHeader
                          className="card-header"
                          style={{
                            // backgroundImage: `url(${amenity.imagen})`,
                            backgroundImage: `url(${apiUrl}/img/amenities/${amenity.id}.jpg)`,
                          }}
                        />
                        <CardContent sx={{ pb: 0 }}>
                          <Typography variant="h5" component="div">
                            <b>{amenity.nombre}</b>
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {amenity.descripcion}
                          </Typography>
                          {amenity.disponibilidad !== "No Disponible" ? (
                            <Typography variant="body1" color="text.secondary">
                                <AccessTimeIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /><b> Horario</b> {amenity.disponibilidad}
                            </Typography>
                          ) : (
                            <Typography variant="body1" color="text.secondary">
                              <b>No disponible hoy</b>
                            </Typography>
                          )}
                          
                          {amenity.reservacion.necesaria ? (
                            <Typography variant="body1" color="text.secondary">
                            <CalendarMonthIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> Requiere Reservacion
                            </Typography>
                            
                          ):
                          ( 
                            <>
                              {!amenity.bloqueado && (
                                <Typography variant="body1" color="text.secondary">
                                  <CheckCircleOutlineIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> Uso Libre
                                </Typography>
                              )}
                            </>
                            
                          )}
                          {amenity.reservacion.monto_pago > 0 && (
                            <Typography variant="body1" color="text.secondary">
                            <PaymentsIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> Requiere Pago
                            </Typography>
                            
                          )}

                          
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end', pr: 1.5, pb: 1.5 }}>
                          <Button color="secondary" variant="contained" size="medium" onClick={() => setRedirect({ trigger: true, route: `/amenidad?id=${amenity.id}` })}>
                            Mas Detalles
                          </Button>
                        </CardActions>
                      </Card>
                    ))
                  )
                )}
              </>
            )}
          </motion.div>
        </AnimatePresence>
      
    </Box>
    {AuthService.userRoleIncludes(["admin","receptor"]) && (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <Fab color="secondary" aria-label="add" sx={fabStyle} variant="extended" onClick={() => setRedirect({ trigger: true, route: '/reservaciones' })}>
          <CalendarMonthIcon sx={{ mr: 1}}/>
          Todas las Reservaciones
        </Fab>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} /> */}
          <Fab 
            color="secondary" 
            aria-label="admin" 
            sx={{...fabStyle}} 
            variant="extended" 
            onClick={() => setRedirect({ trigger: true, route: '/amenityadmin' })}
          >
            <AdminPanelSettingsIcon sx={{ mr: 1}}/>
            Administrar Amenidades
          </Fab>
      </Box>
    )}
    <BackgroundImage />
    </Container>
  );
}