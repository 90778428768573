import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppConfig from '../services/app-config';
import eventReportService from '../services/event-report';
import { Navigate, useLocation } from 'react-router-dom';

import BackgroundImage from '../components/Cityline';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://migarita.online">
        MiGarita.Online
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App({setNavbarState, navbarState}) {
  const [email, setEmail] = React.useState("");
  const [org, setOrg] = React.useState("");
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [password, setPassword] = React.useState("");
  const [severity, setSeverity] = React.useState("warning");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: false, showOptions: false, showSearch: false})
    try {
      const data = parseJwt(token);
      setEmail(data.email);
      setOrg(data.org);
    } catch (err) {
      setRedirect({ trigger: true, route: '/login' });
    }
  }, [token]);

  const reset = async () => {
    if (password === "" || confirmPassword === "") {
      setSnackbarOpen(true);
      setSeverity("warning")
      setSnackbarMessage("Porfavor establece una contraseña.");
      return;
    }

    if (password.length < 8) {
      setSnackbarOpen(true);
      setSeverity("warning")
      setSnackbarMessage("Porfavor usa al menos 8 caracteres para tu contraseña.");
      return;
    }

    if (password !== confirmPassword) {
      setSnackbarOpen(true);
      setSeverity("warning")
      setSnackbarMessage("Las contraseñas no son iguales.");
      return;
    }
    setShowBackdrop(true);

    try {
      const res = await axios.post(`${apiUrl}/reset/login`, { password: password, token: token});
      AppConfig.parseResponse(res);
      if (res.status === 200) {
        setSnackbarOpen(true);
        setSeverity("success");
        setSnackbarMessage("Contraseña restablecida");
        AppConfig.parseResponse(res);
        setShowBackdrop(false);
        eventReportService('password-reset', { email: email, org: org });
        setTimeout(function(){ window.location.href= '/';}, 2000);
      }
    } catch (err) {
      if (err.response.data && err.response.data.code === 401) {
        setSnackbarOpen(true);
        setSnackbarMessage('El enlace que has utilizado es incorrecto o ya ha vencido.');
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage('Error al restablecer la contraseña, intenta de nuevo en otro momento.');
      }
      setShowBackdrop(false);
      setTimeout(function(){ window.location.href= '/login';}, 3000);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ mt: 4 }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0 }}>
          <img src="/logo.svg" alt="Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '20%' }} />
        <Typography variant="h5" color="textSecondary" sx={{ fontFamily: 'Roboto', mb: 2 , mt: 2}}>
          Restablecer Contraseña
        </Typography>
        </Box>
        <Divider variant="middle" />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0, mt: 4 }}>
          <TextField size="medium" fullWidth type='email' id='email' sx={{ mb: 6, mt: 1.2 }} label="Correo Electronico" variant="outlined" value={email} disabled InputProps={{ readOnly: true }} />
          <TextField size="medium" fullWidth type='password' id='password' sx={{ mb: 1.2 }} label="Contraseña" helperText="* Al menos 8 caracteres." variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} />
          <TextField size="medium" fullWidth type='password' id='confirmPassword' sx={{ mb: 4 }} label="Confirmar Contraseña" variant="outlined" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
          <Button fullWidth size="large" variant="contained" color="secondary" onClick={reset}>
            Restablecer
          </Button>
        </Box>
      </Box>
      <Box sx={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Copyright />
      </Box>
    <BackgroundImage />
    </Container>
  );
}