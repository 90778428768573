import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Box, Input } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import BackgroundImage from '../components/Cityline';

import {QrScanner} from "react-qrcode-scanner";

import AuthService from '../services/auth';
import AppConfig from '../services/app-config';

import { Navigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';

function QRCode({setNavbarState, navbarState}) {
    const [redirect, setRedirect] = useState({ trigger: false, route: '/' });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [viewFocused, setViewFocused] = useState(true);
    const qrScannerRef = useRef(null);

    const publicUrl = process.env.REACT_APP_PUBLIC_URL;

    const userCors = process.env.REACT_APP_USE_CORS;
    if ( userCors === "true") {
        axios.defaults.withCredentials = true
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbar({ ...snackbar, open: false });
      };
    


    const handleScan = (data) => {
        const baseUrl = `${publicUrl}/recepcion?codigo=`;
        if (data.startsWith(baseUrl)) {
            data = data.substring(baseUrl.length);
        } else if (data.length !== 6) {
            return;
        }
        // setViewFocused(false);
        // setRedirect({ trigger: true, route: `/recepcion?codigo=${data}&qr=1` });
        window.location.href = `/recepcion?codigo=${data}&qr=1`;
    }

    const handleCancel = () => {
        // setViewFocused(false);
        window.location.href = '/recepcion'
    }

    useEffect(() => {
        if (!AuthService.userRoleIncludes(["admin","receptor"])) {
            setRedirect({ trigger: true, route: '/inicio' });
        }
        setNavbarState({ ...navbarState, showBack: false, backTo: '/recepcion', showOptions: false, showSearch: false });
        
        const handleFocus = () => setViewFocused(true);
        const handleBlur = () => setViewFocused(false);

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
            setViewFocused(false);
        };
    }, [])

    return (
        <Box>
            {redirect.trigger && (
                <Navigate to={redirect.route} replace={true} />
            )}
            <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                {snackbar.message}
                </Alert>
            </Snackbar>
            <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box
                sx={{ mt: 2 ,py: 0.5, px: 0.5, display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: "550px" }}
            >
                <CardContent>
                    {/* <Typography variant="h5" color="textSecondary" sx={{mb: 8}}>
                        Escanea el QR
                    </Typography> */}
                    
                </CardContent>

                <Box sx={{ height: '100%' }}>
                    {viewFocused && (
                        <QrScanner
                            ref={qrScannerRef}
                            onScan={(result) => handleScan(result)}
                            onError={(error) => console.log(error)}
                            showViewFinder={true}
                            resolution={600}
                            facingMode="environment"    
                            legacyMode={false}
                            aspectRatio='3:4'
                            delay={500}
                            flipHorizontally={true}
                            reactivate={true}
                            reactivateTimeout={2000}
                        />
                    )}
                    
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2,  py: 0.5, px: 0.5 }}>
                    {/* <Typography variant="caption" color="textSecondary">
                        Asegurate que la pantalla que escaneas tiene suficiente brillo y la imagen este enfocada
                    </Typography> */}
                    <Button variant="text" color="primary" size="large" sx={{mt : 1.5}} onClick={handleCancel}>Cancelar</Button>
                </Box>
                
            </Box>
            <BackgroundImage />
        </Box>
    );
}

export default QRCode;

