import axios from 'axios';

export default function eventReportService(eventName,payload) {
    const apiUrl = process.env.REACT_APP_MGO_API_URL
    const userCors = process.env.REACT_APP_USE_CORS;
    if ( userCors === "true") {
    axios.defaults.withCredentials = true
    }


    try {
        const response = axios.post(`${apiUrl}/event/report`, {
            evento: eventName,
            data: payload
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return error;
    }
}