import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Divider } from '@mui/material';
import axios from 'axios';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import BackgroundImage from '../components/Cityline';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import dayjs from 'dayjs';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import AppConfig from '../services/app-config';

import { Navigate } from 'react-router-dom';

export default function Users({setNavbarState, navbarState, window}) {
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const [approvals, setApprovals] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [approvalCache,setApprovalCache] = React.useState({})
  const [confirmDialog, setConfirmDialog] = React.useState({ open: false, approval: null });

  const unitType = localStorage.getItem('unit_type');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const closeConfirmDialog = () => {
    setConfirmDialog({ open: false, approval: null });
  }

  // filter the approvals based on the search value
  const filteredApprovals = approvals.filter(approval =>
    approval.nombre.toLowerCase().includes(searchValue.toLowerCase())
    || approval.usuario.toLowerCase().includes(searchValue.toLowerCase())
    || approval.unidad.toLowerCase().includes(searchValue.toLowerCase())
    || approval.nombre_amenidad.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  function convertEpochsToTimeString(epoch1, epoch2) {
    const date1 = new Date(epoch1 * 1000);
    const date2 = new Date(epoch2 * 1000);

    const day = date1.getDate();
    const month = date1.toLocaleString('default', { month: 'short' });
    const time1 = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const time2 = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${day}/${month} ${time1} - ${time2}`;
  }

  const approvePre = (id) => {
    let approvalObj = approvalCache[id]
    approvalObj["id"] = id
    approvalObj['action'] = 'aprobar'
    approvalObj['title'] = 'Confirmar Aprobacion'
    setConfirmDialog({ open: true, approval: approvalObj });
  }

  const removePre = (id) => {
    let approvalObj = approvalCache[id]
    approvalObj["id"] = id
    approvalObj['action'] = 'eliminar'
    approvalObj['title'] = 'Confirmar Eliminacion'
    setConfirmDialog({ open: true, approval: approvalObj });
  }

  const runConfirmedAction = (id,action) => {  
    if (action === 'aprobar') {
      approve(id)
    } else if (action === 'eliminar') {
      remove(id)
    }
  }

  const approve = (id) => {
    const data = { reservacion: id };
    closeConfirmDialog()
    setLoading(true)
    axios.post(`${apiUrl}/approvals/approve`, data)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: "Se ha aprobado la reservacion exitosamente.", severity: 'success' });
          setLoading(false)
          fetchPendingApprovals()
        }
      })
      .catch((error) => {
        setSnackbar({ open: true, message: "Hubo un error al aprobar la reservacion, intenta de nuevo mas tarde.", severity: 'error' });
        console.error(error);
        setLoading(false)
      });
  }

  const convertEpochToDateString = (timestampString) => {
    const timestamp = timestampString.split('-')[1];
    const date = dayjs.unix(timestamp);
    return date.format('DDMMYY');
  };

  const remove = (id) => {
    const data = { reservacion: id, dia: convertEpochToDateString(id) };
    closeConfirmDialog()
    setLoading(true)
    axios.post(`${apiUrl}/reservation/delete`, data)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: "Se ha eliminado la reservacion exitosamente.", severity: 'success' });
          setLoading(false)
          fetchPendingApprovals()
        }
      })
      .catch((error) => {
        setSnackbar({ open: true, message: "Hubo un error al eliminar la reservacion, intenta de nuevo mas tarde.", severity: 'error' });
        console.error(error);
        setLoading(false)
      });
  }

  const fetchPendingApprovals = () => {
    setLoading(true)
    axios.get(`${apiUrl}/approvals/pending`)
         .then((response) => {
            AppConfig.parseResponse(response);
            if (response.status === 200) {
              const dataArray = Object.entries(response.data.data).map(([id, value]) => ({ id, ...value }));
              setApprovals(dataArray)
              setApprovalCache(response.data.data)
              setLoading(false)
            }
         })
         .catch(error => {
          console.error(error);
          if (error.response && error.response.data && error.response.data.code === 401) {
            setRedirect({ trigger: true, route: '/login' })
          }
          console.error(error);
          setLoading(false)
         })
  }


  // Get the approvals when the component is rendered
  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    fetchPendingApprovals()
  }, [])

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  
  return (
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Snackbar open={snackbar.open} autoHideDuration={5000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Box sx={{ ml: 0 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <TextField 
            fullWidth 
            size="medium" 
            disabled={approvals.length === 0}
            id='search' 
            sx={{ textAlign: 'center', mb: 0, my: 1.5 }} 
            label="Busqueda" 
            variant="outlined" 
            onChange={handleSearchChange}
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton>
                    <SearchIcon />
                    </IconButton>
                </InputAdornment>
                ),
                style: { borderRadius: '8px'}
            }}
            />
        {!loading && approvals.length === 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 150px)' }}>
          <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
            <CardContent>
              <PublishedWithChangesIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
              <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay aprobaciones pendientes</b></Typography>
              <Divider />
              <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Revisa mas tarde.</Typography>
            </CardContent>
          </Card>
        </Box>
        )}
        {!loading && filteredApprovals.map((approval) => (
          <Box key={approval.id} sx={{my: 1}}>
            <Card key={`card-${approval.id}`} variant="outlined" style={{borderRadius: '0px', border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {approval.nombre}
                </Typography>
                <Typography variant="body2" color="text.primary" style={{ fontSize: '1.2rem' }}>
                  {approval.nombre_amenidad}
                  <br/>
                  {convertEpochsToTimeString(approval.inicio, approval.fin)}
                  <br />
                </Typography>
                <Typography variant="body2" color="text.secondary" >
                  {approval.unit === "Administracion" || approval.unit === "Recepcion" ? "Unidad" : unitType}: {approval.unidad}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', mb: 2 }}>
                <Button size="medium" variant="text" color="primary" sx={{mr: 2}} onClick={() => removePre(approval.id)}>
                  Eliminar
                </Button>
                <Button size="large" variant='contained' color="secondary" sx={{px: 8}} onClick={() => approvePre(approval.id)}>
                  Aprobar
                </Button>
              </CardActions>
            </Card>
          </Box>
        ))}
      </Box>
      {confirmDialog.open && (<Dialog open={confirmDialog.open} onClose={closeConfirmDialog}>
        <DialogTitle>{confirmDialog.approval.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Typography>¿Esta seguro de que desea {confirmDialog.approval.action} la reservacion de <b>{confirmDialog.approval.nombre_amenidad} a nombre de {confirmDialog.approval.nombre}</b> ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => runConfirmedAction(confirmDialog.approval.id,confirmDialog.approval.action)} color="secondary">
            Confirmar
          </Button>
        </DialogActions>
    </Dialog>)}
    <BackgroundImage />
    </Container>
  );
}