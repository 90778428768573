import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline';
import Fab from '@mui/material/Fab';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import CarCrashIcon from '@mui/icons-material/CarCrash';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import axios from 'axios';
import AppConfig from '../services/app-config';
import AuthService from '../services/auth.js';
import { CardHeader } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';

export default function ParkingShare({setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [mySharingState, setMySharingState] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [currentRequests, setCurrentRequests] = React.useState([]);
  const [confirmedRequests, setConfirmedRequests] = React.useState([]);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [tabValue, setTabValue] = React.useState('current');
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const [drawerShow, setDrawerShow] = React.useState(false);
  const [newRequestTimeStart, setNewRequestTimeStart] = React.useState('');
  const [newRequestTimeEnd, setNewRequestTimeEnd] = React.useState('');
  const [newRequestPlates, setNewRequestPlates] = React.useState('');
  const [validPlates, setValidPlates] = React.useState(true);
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = React.useState('');
  
  let tab = new URLSearchParams(location.search).get('tab');

  let plateRegex = /^[0-9]{3}-[A-Z]{3}$/;

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    fetchInfo(newValue);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleClick = (route) => {
    setRedirect({ trigger: true, route: `/${route.toLowerCase()}` })
}

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const cardStyle = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
    };

    const iconStyle = {
        position: 'absolute',
        right: -20,
        bottom: -20,
        fontSize: 105,
        color: 'rgba(0, 0, 0, 0.10)',
    };

    const fetchMySharingState = () => {
      axios.get(`${apiUrl}/parksharing/status`)
        .then(response => {
          AppConfig.parseResponse(response);
          setMySharingState(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching parking sharing state:', error);
          if (error.response && error.response.data && error.response.data.code === 401) {
            setRedirect({ trigger: true, route: '/login' })
          }
        });
    }

  const fetchInfo = (currentTabValue) => {
    setShowBackdrop(true);
    setLoading(true);
    
    const endpoint = currentTabValue === 'current' ? 'parksharing/current/requests' : 'parksharing/confirmed/requests';
    
    axios.get(`${apiUrl}/${endpoint}`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          const dataArray = Object.entries(response.data.data).map(([id, value]) => ({ id, ...value }));
          if (currentTabValue === 'current') {
            setCurrentRequests(dataArray.reverse());
          } else {
            setConfirmedRequests(dataArray.reverse());
          }
          setShowBackdrop(false);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching parking requests:', error);
        if (error.response && error.response.data && error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' })
        }
      });
  }

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    if (tab !== null) { 
      setRedirect({ trigger: true, route: '/' });
      setTabValue(tab);
    }
    fetchMySharingState();
    fetchInfo(tabValue);
    const nextHour = new Date();
    const offset = new Date().getTimezoneOffset();
    nextHour.setMinutes(0);
    nextHour.setSeconds(0);
    nextHour.setMilliseconds(0);
    nextHour.setHours(nextHour.getHours() + 2 - offset/60);
    setNewRequestTimeStart(nextHour.toISOString().slice(0, 16));
    nextHour.setHours(nextHour.getHours() + 6);
    setNewRequestTimeEnd(nextHour.toISOString().slice(0, 16));
    
    // Set current date and time
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setCurrentDateTime(now.toISOString().slice(0, 16));
  }, [tabValue]);

  const [swipeDirection, setSwipeDirection] = React.useState(null);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (tabValue === 'current') {
        setSwipeDirection('left');
        setTabValue('confirmed');
      }
    },
    onSwipedRight: () => {
      if (tabValue === 'confirmed') {
        setSwipeDirection('right');
        setTabValue('current');
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const pageVariants = {
    enter: (direction) => {
      return {
        x: direction === 'left' ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        x: direction === 'left' ? -1000 : 1000,
        opacity: 0
      };
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  const handleDrawerToggle = () => {
    setDrawerShow((prevState) => !prevState);
    // if (!drawerShow) {
    //   setNewRequestTimeStart('');
    //   setNewRequestTimeEnd('');
    // }
  };

  const handleNewRequest = () => {
    setDrawerShow(true);
  };

  const submitNewRequest = () => {
    if (newRequestTimeStart && newRequestTimeEnd) {
    
      // Convert start and end times to Linux epoch integers
      const startEpoch = Math.floor(new Date(newRequestTimeStart).getTime() / 1000);
      const endEpoch = Math.floor(new Date(newRequestTimeEnd).getTime() / 1000);

      // Use the epoch integers in the API request
      axios.post(`${apiUrl}/parksharing/ask`, { time_start: startEpoch, time_end: endEpoch, plates: newRequestPlates })
        .then(response => {
          AppConfig.parseResponse(response);
          if (response.status === 200) {
            setSnackbar({ open: true, message: "Solicitud de parqueo enviada exitosamente", severity: "success" });
            fetchInfo(tabValue);
            setDrawerShow(false);
          }
        })
        .catch(error => {
          console.error('Error submitting parking request:', error);
          if (error.response && error.response.data && error.response.data.code === 422) {
            setSnackbar({ open: true, message: error.response.data.msg, severity: "error" });
          } else {
            setSnackbar({ open: true, message: "Error al enviar la solicitud de parqueo", severity: "error" });
          }
        });
    } else {
      setSnackbar({ open: true, message: "Por favor, ingrese la fecha y hora de inicio y fin", severity: "warning" });
    }
  };

  const newRequestDrawer = (
    <Card sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ mb: 2 }}>
          Solicitar Parqueo
        </Typography>
        <TextField
          sx={{ mb: 4 }}
          fullWidth
          size="medium"
          value={newRequestTimeStart}
          id='timeStart'
          label="Fecha y Hora de Inicio"
          type="datetime-local"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            min: currentDateTime
          }}
          onChange={(e) => {
            const selectedDateTime = new Date(e.target.value);
            const currentDateTime = new Date();
            
            if (selectedDateTime >= currentDateTime) {
              setNewRequestTimeStart(e.target.value);
              const offset = new Date().getTimezoneOffset();
              const endTime = new Date(selectedDateTime.getTime() + 6 * 60 * 60 * 1000 - offset * 60 * 1000);
              setNewRequestTimeEnd(endTime.toISOString().slice(0, 16));
            } else {
              setSnackbar({ open: true, message: "No se puede seleccionar una fecha y hora pasada", severity: "warning" });
            }
          }}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          size="medium"
          value={newRequestTimeEnd}
          id='timeEnd'
          label="Fecha y Hora de Fin"
          type="datetime-local"
          variant="outlined"
          inputProps={{
            min: newRequestTimeStart
          }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const offset = new Date().getTimezoneOffset();
            const selectedDateTime = new Date(e.target.value);
            const startDateTime = new Date(newRequestTimeStart);
            selectedDateTime.setMinutes(selectedDateTime.getMinutes());
            if (selectedDateTime > startDateTime) {
              setNewRequestTimeEnd(e.target.value);
            } else {
              setSnackbar({ open: true, message: "La fecha y hora de fin no puede ser anterior a la de inicio", severity: "warning" });
            }
          }}
        />
        <TextField
          sx={{ my: 2 }}
          fullWidth
          value={newRequestPlates}
          onChange={(e) => {
            if (e.target.value.length === 3 && newRequestPlates.length < e.target.value.length && validPlates) {
              e.target.value = e.target.value + '-';
            }
            setNewRequestPlates(e.target.value.toUpperCase());
            var complementedValue = e.target.value.toUpperCase().padEnd(7, '777-FFF'.slice(e.target.value.length,));
            setValidPlates(plateRegex.test(complementedValue) && newRequestPlates.length <= 7);
          }}
          label="Placas"
          helperText={validPlates ? <span style={{ color: 'text.secondary' }}>Introduzca las placas del vehiculo que utilizara el estacionamiento.</span> : <Typography variant="body2" color="error" sx={{ mt: 0 }}>Formato de placas invalido, ejemplo valido: 777-FFF</Typography >}
          variant="outlined"
        />
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="medium" onClick={handleDrawerToggle} color="inherit" variant="text" sx={{ mr: 1 }}>Cancelar</Button>
        <Button size="medium" onClick={submitNewRequest} color="secondary" sx={{ px: 4 }} variant="contained">Enviar Solicitud</Button>
      </CardActions>
    </Card>
  );

  const handleViewDetails = (requestId) => {
    navigate(`/solicitudparqueo?id=${requestId}`);
  };

  return (
    <Container maxWidth="sm" {...swipeHandlers}>
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Box sx={{ mt: 1 }}>
        <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="parking tabs" variant="fullWidth" sx={{ mt: 0, mb: 0.2 }} >
          <Tab label="Solicitudes Pendientes" value={'current'}/>
          <Tab label="Solicitudes Confirmadas" value={'confirmed'}/>
        </Tabs>

        {/* Add the parking management cards here, outside of the AnimatePresence */}
        {mySharingState && !mySharingState.parking && (
          <Grid item xs={12} sx={{ mb: 4, mt: 2 }}>
            <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={event => handleClick('onboardingparqueo')}>
              <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Compartir mi Parqueo
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Registra tus datos.
                </Typography>
              </CardContent>
              <CarCrashIcon sx={iconStyle} />
            </Card>
          </Grid>
        )}
        {mySharingState && mySharingState.parking && (
          <Grid item xs={12} sx={{ mb: 4, mt: 2 }}>
            <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={event => handleClick('onboardingparqueo')}>
              <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: -1 }}>
                  Administrar mi Parqueo
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary" sx={{ mb: 1 }}>
                  {mySharingState.parking}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Tu parqueo ya esta configurado, aqui puedes modificar cualquier dato de tu parqueo.
                </Typography>
              </CardContent>
              <NoCrashIcon sx={iconStyle} />
            </Card>
          </Grid>
        )}

        <AnimatePresence initial={false} custom={swipeDirection}>
          <motion.div
            key={tabValue}
            custom={swipeDirection}
            variants={pageVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={pageTransition}
          >
            {tabValue === "confirmed" ? (
              <>
                {!loading && (
                  confirmedRequests.length === 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 400px)' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
                                <CardContent>
                            <LocalParkingIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
                            <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay solicitudes confirmadas</b></Typography>
                            <Divider />
                            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Aquí podrás ver las solicitudes de parqueo que has solicitado o aceptado.</Typography>
                            </CardContent>
                        </Card>
                      </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <>
                      {confirmedRequests.map((request) => (
                        <React.Fragment key={request.id}>
                          <Card
                            variant="outlined"
                            sx={{ mb: 1.2, border: 0, mt: 1 }}
                            onClick={() => handleViewDetails(request.id)}
                          >
                            <CardContent sx={{ pb: 0, fontWeight: 'bold' }}>
                              <Typography variant="h5" component="div">
                                {request.created_by === AuthService.getCurrentUserEmail() ? 'Tu Solicitud' : `Compartiendo a ${AppConfig.titleCase(request.requester_name)}`}
                              </Typography>
                              <Typography variant="body1" color="text.secondary">
                                <ArrowCircleRightIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Estado:</b> {new Date() > new Date(request.time_start) && new Date() < new Date(request.time_end) ? 'Transcurriendo' : 'Confirmada'}
                              </Typography>
                              <Typography variant="body1" color="text.secondary">
                                <AccessTimeIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Inicio:</b> {AppConfig.formatFriendlyDate(request.time_start)}
                              </Typography>
                              <Typography variant="body1" color="text.secondary">
                                <AccessTimeIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Fin:</b> {AppConfig.formatFriendlyDate(request.time_end)}
                              </Typography>
                              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mb: -1 }}>
                                <Button 
                                  variant="contained" 
                                  color="secondary" 
                                  sx={{ px: 4 }}
                                  onClick={() => handleViewDetails(request.id)}
                                >
                                  Ver Detalles
                                </Button>
                              </Box>
                            </CardContent>
                          </Card>
                          <Divider sx={{ mt: -2}} />
                        </React.Fragment>
                      ))}
                    </>
                  )
                )}
              </>
            ) : (
              <>
                {!loading && (
                  currentRequests.length === 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 400px)' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
                                <CardContent>
                            <LocalParkingIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
                            <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay solicitudes pendientes</b></Typography>
                            <Divider />
                            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Aquí podrás ver las solicitudes de parqueo pendientes.</Typography>
                            </CardContent>
                        </Card>
                      </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <>
                      {currentRequests.map((request) => (
                        <React.Fragment key={request.id}>
                          <Card
                            variant="outlined"
                            sx={{ mb: 1.2, border: 0, mt: 1 }}
                            onClick={() => handleViewDetails(request.id)}
                          >
                            <CardContent sx={{ pb: 0 }}>
                              <Typography variant="h5" component="div">
                                {request.created_by === AuthService.getCurrentUserEmail() ? 'Tu Solicitud' : `Solicitud de Parqueo`}
                              </Typography>
                              <Typography variant="body1" color="text.secondary">
                                <ArrowCircleRightIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Estado:</b> {new Date() > new Date(request.time_start) && new Date() < new Date(request.time_end) ? 'Pendiente, trasncurriendo ' : 'Pendiente'}
                              </Typography>
                              <Typography variant="body1" color="text.secondary">
                                <PersonIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Solicitante:</b> {AppConfig.titleCase(request.requester_name)}
                              </Typography>
                              <Typography variant="body1" color="text.secondary">
                                <AccessTimeIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Inicio:</b> {AppConfig.formatFriendlyDate(request.time_start)}
                              </Typography>
                              <Typography variant="body1" color="text.secondary">
                                <AccessTimeIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Fin:</b> {AppConfig.formatFriendlyDate(request.time_end)}
                              </Typography>
                              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mb: -1 }}>
                                <Button 
                                  variant="contained" 
                                  color="secondary" 
                                  sx={{ px: 4 }}
                                  onClick={() => handleViewDetails(request.id)}
                                >
                                  Ver Detalles
                                </Button>
                              </Box>
                            </CardContent>
                          </Card>
                          <Divider sx={{ mt: -2}} />
                        </React.Fragment>
                      ))}
                    </>
                  )
                )}
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
      <Drawer
        anchor="bottom"
        open={drawerShow}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        sx={{
          display: { xs: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
        }}
      >
        {newRequestDrawer}
      </Drawer>
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
        <Fab
          sx={fabStyle}
          variant="extended"
          color="secondary"
          size="large"
          aria-label="add"
          onClick={handleNewRequest}
        >
          <LocalParkingIcon sx={{ mr: 1 }} />
          Solicitar Parqueo
        </Fab>
      </Box>
      <BackgroundImage />
    </Container>
  );
}