import * as React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Navigate } from 'react-router-dom';
import AppConfig from '../services/app-config';
import Grid from '@mui/material/Grid';
import ShareIcon from '@mui/icons-material/Share';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const apiUrl = process.env.REACT_APP_MGO_API_URL;

export default function ShareCode({setNavbarState, navbarState}) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
    const [imageSrc, setImageSrc] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const userCors = process.env.REACT_APP_USE_CORS;
    if (userCors === "true") {
        axios.defaults.withCredentials = true;
    }

    const deactivateCode = async () => {
        setOpenConfirmDialog(false);
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/visit/invalidate`, { access_code: code });
            AppConfig.parseResponse(response);
            if (response.status === 200) {
                setRedirect({ trigger: true, route: '/visitas' });
            }
        } catch (error) {
            setRedirect({ trigger: true, route: '/visitas' });
            console.log(error);
        }
        setLoading(false);
    };

    const shareImage = async (share = true) => {
        const config = {
            responseType: 'blob',
        };
        const blob = await axios.get(`${apiUrl}/visit/create/image/${code}`, config)
            .then(res => new Blob([res.data], { type: res.headers['content-type'] }))
            .catch(error => {
                console.log(error);
                if (error.response.data.code === 401) {
                    setRedirect({ trigger: true, route: '/login' });
                } else {
                    setRedirect({ trigger: true, route: '/visits' });
                }
            });
        const reader = new FileReader();
        reader.onloadend = function () {
            setImageSrc(reader.result);
            setLoading(false);
        };
        reader.readAsDataURL(blob);
        const data = {
            files: [
                new File([blob], `Acceso_${code}.jpg`, {
                    type: blob.type,
                }),
            ]
        };

        if (share) {
            if (!navigator.share) return;
            try {
                await navigator.share(data);
            } catch (err) {
                console.error(err);
            }
        }
    };

    React.useEffect(() => {
        shareImage(false);
        setNavbarState({ ...navbarState, showBack: true, showOptions: false, showSearch: false});
    }, []);

    return (
        <Box sx={{ mt: 1, position: 'relative', height: '100vh' }}>
            {redirect.trigger && (
                <Navigate to={redirect.route} replace={true} />
            )}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {!loading && (
                <>
                    <Box component="div" sx={{ overflow: 'auto', height: 'calc(100vh - 120px)' }}>
                        <img src={imageSrc} alt="API Image" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: "99%", height: 'auto' }} />
                    </Box>
                    <Card sx={{
                        position: 'fixed',
                        py: 1,
                        bottom: 30,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: 'calc(100% - 32px)',
                        maxWidth: '400px',
                        borderRadius: 2,
                        boxShadow: 3,
                    }}>
                        <CardActions sx={{ flexDirection: 'column', padding: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button 
                                        variant="contained" 
                                        color="secondary" 
                                        onClick={() => shareImage(true)}
                                        fullWidth
                                        size="large"
                                        startIcon={<ShareIcon />}
                                    >
                                        Compartir
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button 
                                        variant="outlined" 
                                        color="primary" 
                                        onClick={() => setOpenConfirmDialog(true)}
                                        fullWidth
                                        size="large"
                                        startIcon={<CancelIcon />}
                                    >
                                        Anular Acceso
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                    <Dialog
                        open={openConfirmDialog}
                        onClose={() => setOpenConfirmDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"¿Estás seguro de que quieres anular este acceso?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Esta acción no se puede deshacer. El acceso ya no será válido después de anularlo.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={deactivateCode} color="error" autoFocus>
                                Anular
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Box>
    );
}