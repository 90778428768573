import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BackgroundImage from '../components/Cityline';

import AppConfig from '../services/app-config';

import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';

export default function Users({setNavbarState, navbarState, window}) {
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const [users, setUsers] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });

  const unitType = localStorage.getItem('unit_type');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  // filter the users based on the search value
  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchValue.toLowerCase())
    || user.lastname.toLowerCase().includes(searchValue.toLowerCase())
    || user.phone.toLowerCase().includes(searchValue.toLowerCase())
    || user.unit.toLowerCase().includes(searchValue.toLowerCase())
  );

  // function to fetch all users
  const fetchUsers = () => {
    axios.get(`${apiUrl}/users/allusers`)
         .then((response) => {
            AppConfig.parseResponse(response);
            if (response.status === 200) {
              const sortedUsers = response.data.data.sort((a, b) => a.name.localeCompare(b.name));
              setUsers(sortedUsers);
              setLoading(false);
            }
         })
         .catch(error => {
           console.error(error);
           if (error.response.data.code === 401) {
            setRedirect({ trigger: true, route: '/login' })
          } else if (error.response.data.code === 403) {
            localStorage.setItem('appear_in_directory', false)
            location.href = '/'
          }
         })
  }


  // Get the users when the component is rendered
  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    fetchUsers()
  }, [])

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  
  return (
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Box sx={{ ml: 0 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <TextField 
            fullWidth 
            size="medium" 
            id='search' 
            sx={{ textAlign: 'center', mb: 0, my: 1.5 }} 
            label="Busqueda" 
            variant="outlined" 
            onChange={handleSearchChange}
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton>
                    <SearchIcon />
                    </IconButton>
                </InputAdornment>
                ),
                style: { borderRadius: '8px'}
            }}
            />
        {!loading && filteredUsers.map((user) => (
          <Box key={user.username} sx={{my: 1.5}}>
            <Card variant="outlined" style={{borderRadius: '0', border: 'none', borderBottom: '1px solid #e0e0e0'}}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {user.name} {user.lastname} 
                  <a href={`tel:${user.phone}`} style={{ float: 'right', color: 'inherit', textDecoration: 'none' }}>
                    <LocalPhoneIcon color="secondary" />
                  </a>
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontSize: '1.2rem' }}>
                  Telefono: {user.phone}
                  <br />
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                  {user.unit === "Administracion" || user.unit === "Recepcion" ? "Unidad" : unitType}: {user.unit}
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<WhatsAppIcon />}
                  href={`https://wa.me/${user.phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  fullWidth
                >
                  Chatear por WhatsApp
                </Button>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    <BackgroundImage />
    </Container>
  );
}