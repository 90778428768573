import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#FCFDFF'
    },
    primary: {
      main: '#021545',
    },
    secondary: {
      main: '#238048',
    },
    action: {
      hover: '#38C76C',
    },
    error: {
      main: "#c92c51",
    },
    info: {
      main: '#4871e6',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
  },
});

export default theme;