import * as React from 'react';
import { useState, useCallback } from 'react';
import { Container, Card, CardContent, Typography, TextField, Button, Stepper, Step, StepLabel, Box, Backdrop, CircularProgress, Drawer, List, ListItem, ListItemIcon, ListItemText, CardActions } from '@mui/material';
import { Navigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import axios from 'axios';
import AppConfig from '../services/app-config';
import AuthService from '../services/auth';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ClearIcon from '@mui/icons-material/Clear';
import Resizer from 'react-image-file-resizer';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { getMessaging, getToken } from "firebase/messaging";
import BackgroundImage from '../components/Cityline';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ImageIcon from '@mui/icons-material/Image';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// Add this near the top of your file, after your imports
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data && event.data.type === 'NOTIFICATION_CLICK') {
      // Navigate to the URL
      window.location.href = event.data.url;
    }
  });
}

const ParkingOnboarding = ({setNavbarState, navbarState}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        image: null,
        parking: '',
        indications: '',
        listening: false, // Initialize as a boolean
    });
    const [image, setImage] = useState(null);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [notificationsEnabled, setNotificationsEnabled] = useState(localStorage.getItem('notification_enable') === 'true');
    const [redirect, setRedirect] = useState({ trigger: false, route: '/' });
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [parkingRegx,setParkingRegx] = useState({enable: false, regex: '', msg: "", valid: true});
    const [mySharingState, setMySharingState] = useState({});

    const steps = ['Fotografia de mi Parqueo', 'Detalles del Parqueo', 'Habilitar Notificaciones'];

    const userCors = process.env.REACT_APP_USE_CORS;
    if ( userCors === "true") {
        axios.defaults.withCredentials = true
    }
    
    const apiUrl = process.env.REACT_APP_MGO_API_URL;

    // const container = window !== undefined ? () => window().document.body : undefined;
    const handleNext = () => {
        if (activeStep === 0) {
            if (!image && AppConfig.getLocal('parksharing_require_image')) {
                setSnackbar({ open: true, message: 'Debes incluir una fotografia de tu parqueo', severity: 'error' });
                return;
            }
        } else if (activeStep === 1) {
            if (!formData.parking) {
                setSnackbar({ open: true, message: 'Debes incluir el numero o identificador del parqueo', severity: 'error' });
                return;
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleInputChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === 'parking') {
            if (parkingRegx.enable) {
                setFormData({ ...formData, [name]: value.toUpperCase() });
                var complementedValue = value.toUpperCase().padEnd(parkingRegx.parking_example.length, parkingRegx.parking_example.slice(value.length,));
                let parkingRegex = new RegExp(parkingRegx.regex);
                setParkingRegx({...parkingRegx, valid: parkingRegex.test(complementedValue)});
            } else {
                setFormData({ ...formData, [name]: value.toUpperCase() });
            }
        } else if (name === 'listening') {
            setFormData({ ...formData, [name]: checked }); // Use checked for boolean values
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbar({ ...snackbar, open: false });
      };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file.size > 1024 * 1024) {
            setShowBackdrop(true);
            Resizer.imageFileResizer(
                file,
                800,
                800,
                'JPEG',
                80,
                0,
                (compressedFile) => {
                    setImage(compressedFile);
                    setFormData({ ...formData, image: compressedFile });
                    setShowBackdrop(false);
                },
                'blob'
            );
        } else {
            if (file.type.startsWith('image/')) {
                setImage(file);
                setFormData({ ...formData, image: file });
            } else {
                // Handle error: not an image file
                setSnackbar({ open: true, message: 'El archivo seleccionado no es una imagen', severity: 'error' });
                setShowBackdrop(false);
            }
        }
    };

    const handleSubmit = async () => {
        setShowBackdrop(true);
        const submitedFormData = new FormData();
        submitedFormData.append('parking', formData.parking);
        submitedFormData.append('indications', formData.indications);
        if (mySharingState.parking) {
            submitedFormData.append('listening', formData.listening);
        }
        if (image) {
            submitedFormData.append('image', image);
        }
        try {
            const response = await axios.post(`${apiUrl}/parksharing/register`, submitedFormData);
            if (response.status === 200) {
                setRedirect({ trigger: true, route: '/parqueos' });
            }
        } catch (error) {
            console.error('Error submitting parking onboarding data:', error);
            if (error.response && error.response.data && error.response.data.code === 422) {
                setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
            } else if (error.response && error.response.data && error.response.data.code === 401) {
                setRedirect({ trigger: true, route: '/login' });
            }
        } finally {
            setShowBackdrop(false);
        }
    };

    const requestNotificationPermission = useCallback(() => {
        if ('Notification' in window) {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted');
                        registerToken();
                    } else if (permission === 'denied') {
                        console.log('Notification permission denied');
                    }
                })
                .catch((error) => {
                    console.error('Error requesting notification permission:', error);
                });
        } else {
            console.log('Notifications not supported in this browser');
        }
    }, []);

    const registerToken = useCallback(async () => {
        setLoading(true);
        const messaging = getMessaging();
        try {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
            AppConfig.storeLocal('notification_token_id', token.slice(-6));
            const apiUrl = process.env.REACT_APP_MGO_API_URL;
            const response = await axios.post(`${apiUrl}/users/notification/register`, {
                token: token,
                platform: AppConfig.getDevicePlatform(),
                device: navigator.userAgent
            });
            AppConfig.parseResponse(response);
            if (response.status === 200) {
                AppConfig.storeLocal('notification_enable', 'true');
                setNotificationsEnabled(true);
                console.log('Token registered');
            }
        } catch (error) {
            console.error('Error registering token:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchMySharingState = () => {
        axios.get(`${apiUrl}/parksharing/status/onboarding`)
          .then(response => {
            AppConfig.parseResponse(response);
            setMySharingState(response.data.data);
            var sharingState = response.data.data;
            if (sharingState.image_included) {
              const imageUrl = `${apiUrl}/img/parksharing/${AuthService.getCurrentUserEmail()}.jpg`;
              axios.get(imageUrl, { responseType: 'blob' })
                .then(response => {
                  setImage(response.data);
                })
                .catch(error => {
                  console.error('Error fetching image:', error);
                });
            }
            if (sharingState.parking) {
                setFormData({ 
                    ...formData, 
                    parking: sharingState.parking, 
                    listening: Boolean(sharingState.listening), // Convert to boolean
                    indications: sharingState.indications 
                });
            }
            if (sharingState.parking_regex) {
                setParkingRegx({enable: true, regex: sharingState.parking_regex, msg: sharingState.parking_regex_msg, parking_example: sharingState.parking_example, valid: true});
            }
          })
          .catch(error => {
            console.error('Error fetching parking sharing state:', error);
            if (error.response && error.response.data && error.response.data.code === 401) {
              setRedirect({ trigger: true, route: '/login' })
            }
          });
    }

    React.useEffect(() => {
        setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
        fetchMySharingState();
    }, []);

    const handleImageOptionClick = (option) => {
        setDrawerOpen(false);
        if (option === 'camera') {
            document.getElementById('parking-camera-capture').click();
        } else {
            document.getElementById('parking-image-upload').click();
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' , mb: 4}}>
                            Para ayudar a identificar el parqueo correcto a tu vecino incluye una fotografia de tu parqueo.
                        </Typography>
                        {image && (
                            <Box style={{ position: 'relative', marginBottom: '1rem' }}>
                                <ClearIcon 
                                    style={{ position: 'absolute', top: '-4px', left: '-8px', cursor: 'pointer' }} 
                                    onClick={() => {
                                        setImage(null);
                                        setFormData({ ...formData, image: null });
                                    }} 
                                />
                                <img 
                                    src={URL.createObjectURL(image)} 
                                    alt="Parking Space" 
                                    style={{
                                        maxWidth: '50%',
                                        height: 'auto',
                                        objectFit: 'cover',
                                        borderRadius: '10px',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                />
                            </Box>
                        )}
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<AddPhotoAlternateIcon />}
                            onClick={() => setDrawerOpen(true)}
                            sx={{ p: 2, mb: 4 }}
                        >
                            {image ? "Cambiar" : "Agregar"} Fotografia
                        </Button>
                        <input
                            type="file"
                            id="parking-image-upload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                        <input
                            type="file"
                            id="parking-camera-capture"
                            accept="image/*"
                            capture="environment"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                        <Drawer
                            anchor="bottom"
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            // container={container}
                            variant="temporary"
                            ModalProps={{
                                keepMounted: true,
                            }}
                            PaperProps={{ 
                                elevation: 0, 
                                style: { backgroundColor: "transparent" } 
                            }}
                            sx={{
                                display: { xs: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', maxWidth: '550px', margin: '0 auto' }
                            }}
                        >
                            <Card sx={{ 
                                py: 3, 
                                px: 2, 
                                borderTopLeftRadius: "20px", 
                                borderTopRightRadius: "20px", 
                                borderBottomRightRadius: "0px", 
                                borderBottomLeftRadius: "0px", 
                                width: "100%" 
                            }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>
                                        Agregar Fotografia
                                    </Typography>
                                    <List>
                                        <ListItem button onClick={() => handleImageOptionClick('camera')}>
                                            <ListItemIcon>
                                                <CameraAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Usar Cámara" />
                                        </ListItem>
                                        <ListItem button onClick={() => handleImageOptionClick('file')}>
                                            <ListItemIcon>
                                                <ImageIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Seleccionar Imagen" />
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions>
                                    <Button size="medium" onClick={() => setDrawerOpen(false)} color="inherit" variant="outlined">
                                        Cerrar
                                    </Button>
                                </CardActions>
                            </Card>
                        </Drawer>
                    </>
                );
            case 1:
                return (
                    <>
                        <TextField
                            fullWidth
                            label="Parqueo"
                            name="parking"
                            value={formData.parking}
                            onChange={handleInputChange}
                            placeholder={`Ejemplo: ${parkingRegx.parking_example}`}
                            margin="normal"
                            helperText={parkingRegx.valid ? <span style={{ color: 'gray' }}>Introduzca el numero o identificador del parqueo.</span> : <span style={{ color: 'red' }}>Formato de parqueo invalido, ejemplo valido: {parkingRegx.parking_example}</span>}
                        />
                        <TextField
                            fullWidth
                            label="Indicaciones"
                            name="indications"
                            value={formData.indications}
                            onChange={handleInputChange}
                            margin="normal"
                            multiline
                            rows={3}
                            helperText="Ayuda a tu vecino a identificar el parqueo para evitar que utilice uno incorrecto"
                            sx={{ mb: 4 }}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            {notificationsEnabled ? (
                                <NotificationsActiveIcon color="primary" sx={{ mr: 2 }} />
                            ) : (
                                <NotificationsOffIcon color="disabled" sx={{ mr: 2 }} />
                            )}
                            <Typography variant="body1" sx={{ fontWeight: 'bold', my: 2 }}>
                                {notificationsEnabled 
                                    ? "Las notificaciones están habilitadas, te podremos notificar cuando alguien necesite prestar un parqueo"
                                    : "Para poder ser notificado cuando alguien necesite prestar un parqueo es necesario que habilites las notificaciones."}
                            </Typography>
                        </Box>
            
                        {!notificationsEnabled && (
                            <>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mb: 4 }}
                                onClick={requestNotificationPermission}
                                fullWidth
                            >
                                Habilitar Notificaciones
                            </Button>
                            
                            </>
                        )}
                        {mySharingState.parking && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData.listening}
                                            onChange={handleInputChange}
                                            name="listening"
                                            color="primary"
                                            sx={{ mb: 4 }}
                                        />
                                    }
                                    label="Recibir Notificaciones Sobre Estacionamientos"
                                />
                                <Typography variant="body2" color="textSecondary" sx={{ ml: 0, mb: 4 }}>
                                    Esto únicamente controla las notificaciones de parqueos.
                                </Typography>
                            </>
                        )}
                        <Divider sx={{ mb: 4 }} />
                        {notificationsEnabled && (
                            <>
                            <Typography variant="body1" sx={{ my: 4, textAlign: 'center' }}>
                                Presiona enviar para {mySharingState.parking ? "guardar los cambios." : "terminar el onboarding."}
                            </Typography>
                            </>
                        )}
                    </>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', alignItems: 'center', minHeight: '80vh' }}>
            {redirect.trigger && (
                <Navigate to={redirect.route} replace={true} />
            )}
            <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading || showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ width: '100%' }}>
                <Card sx={{ border: 0, backgroundColor: 'transparent'}} variant="outlined">
                    <CardContent>
                        <Typography variant="h5" component="div" gutterBottom sx={{ mb: 5 }}>
                            Datos Sobre mi Parqueo
                        </Typography>
                        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 5 }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box sx={{ mt: 2 }}>
                            {getStepContent(activeStep)}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                Atras
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                sx={{ px: 6 }}
                                onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                                disabled={activeStep === steps.length - 1 && (!AppConfig.getLocal('notification_enable') && !mySharingState.parking)}
                            >
                                {activeStep === steps.length - 1 ? 'Enviar' : 'Siguiente'}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <BackgroundImage />
        </Container>
    );
};

export default ParkingOnboarding;