import React, { useEffect, useState } from "react";
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import { ListItemButton, ListItemText } from '@mui/material';
import AppConfig from '../services/app-config';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import eventReportService from "../services/event-report";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [openIOSDialog, setOpenIOSDialog] = React.useState(false);
  const [openAndroidDialog, setOpenAndroidDialog] = React.useState(false);


    const handleOpenIOSDialog = () => {
        setOpenIOSDialog(true);
    };
    const handleClose = () => {
        setOpenIOSDialog(false);
    };

    const handleOpenAndroidDialog = () => {
        setOpenAndroidDialog(true);
    }
    const handleAndroidClose = () => {
        setOpenAndroidDialog(false);
    }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const reportInstallEvent = () => {
        eventReportService('install', { platform: AppConfig.getDevicePlatform() });
    }

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    if (AppConfig.getDevicePlatform() === 'ios') {
        setSupportsPWA(true);
    }
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    reportInstallEvent();
    if (AppConfig.getDevicePlatform() === 'ios') {
        handleOpenIOSDialog()
        return;
    }
    if (!promptInstall) {
      return;
    }
    let resp = promptInstall.prompt();
    resp.then((outcome) => {
      if (outcome === 'accepted') {
        handleOpenAndroidDialog()
        console.log("User accepted the A2HS prompt", outcome);
      } else {
        console.log("User dismissed the A2HS prompt", outcome);
      }
      setPromptInstall(null);
    })
    
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <>
    <ListItemButton sx={{ textAlign: 'center'}} onClick={onClick}>
        <ListItemText primary='Instalar App' sx={{ textAlign: 'center' }} />
        <InstallMobileIcon />
    </ListItemButton>
    <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openIOSDialog}
    >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Instalar App en iOS
        </DialogTitle>
        <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        }}
        >
        <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <img
          src="/iossafarishare.jpg"
          alt="iOS Share"
          style={{ width: '100%', border: '15px', paddingLeft: 0, borderRadius: '10px' }}
        />
        <Typography gutterBottom>
            <b>Para instalar la aplicación en iOS, siga los siguientes pasos en su dispositivo:</b><br/>
        </Typography>
        <Typography gutterBottom>
            1. Toque el ícono de "Compartir" en la parte inferior de la pantalla.
        </Typography>
        <Typography gutterBottom>
            2. Desplácese hacia abajo y seleccione "Agregar a la pantalla de inicio".
        </Typography>
        <Typography gutterBottom>
            3. Seleccione "Agregar" en la esquina superior derecha y listo.
        </Typography>
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleClose}>
            Entendido
        </Button>
        </DialogActions>
    </BootstrapDialog>
    <BootstrapDialog
        onClose={handleAndroidClose}
        aria-labelledby="customized-dialog-title"
        open={openAndroidDialog}
    >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        App Instalada Correctamente
        </DialogTitle>
        <IconButton
        aria-label="close"
        onClick={handleAndroidClose}
        sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        }}
        >
        <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Typography gutterBottom>
            <b>La aplicacion ha sido instalada correctamente.</b>
        </Typography>
        <Typography gutterBottom>
            Puedes cerrar el explorador y buscar el app en tu dispositivo.
        </Typography>
        
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleAndroidClose}>
            Entendido
        </Button>
        </DialogActions>
    </BootstrapDialog>
    </>
  );
};

export default InstallPWA;