function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

class AppConfig {
    constructor()  {
        this.defaults ={
            unit_type: "Unidad",
            appear_in_directory: true,
            force_resident_vehicle_plate_input: true,
            modules: "visits",
            starting_date: "2023-11-12"
        }
        this.months = [ "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
    }

    isInstalled() {
        return window.matchMedia('(display-mode: standalone)').matches
    }
    
    getDevicePlatform() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
        if (/android/i.test(userAgent)) {
          return 'android';
        }
        
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'ios';
        }
        
        return 'chrome';
      }

    getLocal(key) {
        const value = localStorage.getItem(key);
        if (value === null) {
            return false
        }
        if (value === "true") {
            return true;
        } else if (value === "false") {
            return false;
        }
        if (isJson(value)) {
            return JSON.parse(value);
        }
        return value
    }

    storeLocal(key,value) {
        localStorage.setItem(key,value);
    }

    getDayOfWeek(d=undefined) {
        if (d === undefined) {
            d = new Date();
        }
        const dayOfWeek = d.getDay();
        return dayOfWeek === 0 ? 7 : dayOfWeek;
    }

    isCurrentDateInRange(minEpoch, maxEpoch, currentDateEpoch=undefined) {
        if (currentDateEpoch === undefined) {
            currentDateEpoch = Math.floor(Date.now() / 1000);
        }
        const minDateEpoch = parseInt(minEpoch);
        const maxDateEpoch = parseInt(maxEpoch);

        return currentDateEpoch >= minDateEpoch && currentDateEpoch <= maxDateEpoch;
    }

    parseResponse(response) {
        if (response.status === 200 && response.data) {
            if ("app_config" in response.data) {
                const appConfig = response.data.app_config;
                for (const key in appConfig) {
                    if (appConfig.hasOwnProperty(key)) {
                        localStorage.setItem(key,appConfig[key]);
                    }
                }
            }
        }
    }

    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }

    formatFriendlyDate(epoch, upperCase = false) {
        const date = new Date(epoch * 1000);
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        let frdate = "";

        if (date.toDateString() === today.toDateString()) {
            frdate = upperCase ? "Hoy" : "hoy";
        } else if (date.toDateString() === tomorrow.toDateString()) {
            frdate = upperCase ? "Mañana" : "mañana";
        } else if (date.toDateString() === yesterday.toDateString()) {
            frdate = upperCase ? "Ayer" : "ayer";
        } else {
            return this.formatDate(epoch);
        }

        return `${frdate} a las ${this.formatHour(epoch)}`;
    }

    haveModule(module) {
        const modules = this.get("modules");
        if (modules === null) {
            return false;
        }
        if (modules === "") {
            return false;
        }
        return modules.includes(module);
    
    }

    formatDate(epoch) {
        const date = new Date(epoch * 1000);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${this.months[month-1]} ${hours}:${minutes}`;
      }

    formatHour(epoch) {
        const date = new Date(epoch * 1000);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      }


    get(key) {
        const value = localStorage.getItem(key);
        if (value === null) {
            return this.defaults[key];
        }
        if (value === "true") {
            return true;
        } else if (value === "false") {
            return false;
        }
        return value
    }
}

export default new AppConfig();