import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../services/auth.js';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import Drawer from '@mui/material/Drawer';

import axios from 'axios';
import AppConfig from '../services/app-config';

// Import new icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BlockIcon from '@mui/icons-material/Block';
import ListAltIcon from '@mui/icons-material/ListAlt';

export default function Home({setNavbarState, navbarState, window}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [amenity, setAmenity] = React.useState({});
  const [amenityReservations,setAmenityReservations] = React.useState({});
  const [drawerShow, setDrawerShow] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [date,setDate] = React.useState(dayjs());
  const [timeRanges, setTimeRanges] = React.useState({});
  const [reservationDialog,setReservationDialog] = React.useState(false);
  const [adminReservationDialog,setAdminReservationDialog] = React.useState({show: false, resident_name: "", resident_unit: "", error: false, error_msg: ""});
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [refresh,setRefresh] = React.useState(false);

  const [adminOptionsDrawerOpen, setAdminOptionsDrawerOpen] = React.useState(false);
  const [blockModalOpen, setBlockModalOpen] = React.useState(false);
  const [blockData, setBlockData] = React.useState({
    motivo: "",
    fecha_inicio: new Date().toISOString().slice(0, 16),
    fecha_fin: new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toISOString().slice(0, 16)
  });

  const amenityId = new URLSearchParams(location.search).get('id');
  const apiUrl = process.env.REACT_APP_MGO_API_URL;

  const weekDays = ["Lunes","Martes","Miercoles","Jueves","Viernes","Sabado","Domingo"]
  const monthsSpa = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octobre","Noviembre","Diciembre"]

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }
  var regex = null
  if (AppConfig.getLocal("unit_regex")) {
    regex = new RegExp(AppConfig.getLocal("unit_regex"));
  }

  var unit_sample = ""
  if (AppConfig.getLocal("unit_sample")) {
    unit_sample = AppConfig.getLocal("unit_sample");
  }

  const timeToString = (time) => {
    if (time < 60) {
      return `${time} minutos`;
    } else {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      if (minutes === 0) {
        return `${hours} horas`;
      } else {
        return `${hours} horas y ${minutes} minutos`;
      }
    }
  };

  const closeReservationDialog = () => {
    setReservationDialog(false);
  }

  const closeAdminReservationDialog = () => {
    setAdminReservationDialog({...adminReservationDialog, show: false});
  }

  const triggerRefresh = () => {
    setRefresh(!refresh);
  }

  const handleAdminReservation = () => {
    setAdminReservationDialog({ ...adminReservationDialog, show: true });
  }

  const handleUnitChange = (e) => {
    var unit_valid = {error: false, error_msg: ""}
    if (AppConfig.getLocal("unit_regex")) {
      var text = e.target.value;
      if (AppConfig.getLocal("unit_sample")) {
        var complement_length = AppConfig.getLocal("unit_sample").length - text.length ;
        var complement_text = AppConfig.getLocal("unit_sample").slice(-complement_length);
        if (complement_length === 0) {
          complement_text = "" ;
        }
        text = `${text}${complement_text}`
      }
      if (!regex.test(text)) {
        unit_valid = {error: true, error_msg: `Formato Incorrecto ej. ${AppConfig.getLocal("unit_sample")}`}
      }
    } 
    setAdminReservationDialog({ ...adminReservationDialog, ...unit_valid, resident_unit: e.target.value });
  }

  const makeReservation = () => {
    setLoading(true);
    setShowBackdrop(true);
    setReservationDialog(false);
    console.log({amenidad: amenityId, inicio: timeRanges.from.value.unix(), fin: timeRanges.to.value.unix(), residente: adminReservationDialog.resident_name, unidad: adminReservationDialog.resident_unit})
    axios.post(`${apiUrl}/reservation/create`, {amenidad: amenityId, inicio: timeRanges.from.value.unix(), fin: timeRanges.to.value.unix(), residente: adminReservationDialog.resident_name, unidad: adminReservationDialog.resident_unit})
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          let default_msg = 'Reservacion creada correctamente'
          if (response.data.data.mensaje) {
            default_msg = response.data.data.mensaje;
          }
          // setSnackbar({ open: true, message: default_msg, severity: 'success' });
          // setReservationDialog(false);
          // fetchInfo(date,amenityId);
          setRedirect({ trigger: true, route: `/reservacion?id=${response.data.data.id}&msg=${default_msg}&lvl=success` });
        } else if (response.status === 422) {
          setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
        }
        setLoading(false);
        setShowBackdrop(false);
      })
      .catch(error => {
        if(error.response && error.response.data && error.response.data.code === 422) {
          setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
        }
        else  {
        console.error('Error fetching reservations:', error);
        setSnackbar({ open: true, message: "Hubo un error al intentar completar la reservacion porfavor intenta de nuevo mas tarde.", severity: 'error' })};
        setLoading(false);
        setShowBackdrop(false);
      });
  }

  const makeReservationPre = () => {
    if (AuthService.getCurrentUserRole() === "admin") {
      if (adminReservationDialog.resident_name === "" || adminReservationDialog.resident_unit === "") {
        setSnackbar({ open: true, message: "Porfavor ingresar todos los datos del residente", severity: 'error' });
        return;
      }
      if (AppConfig.getLocal("unit_regex")) {
        if (!regex.test(adminReservationDialog.resident_unit)) {
          setSnackbar({ open: true, message: `Formato de apartamento incorrecto ej. ${unit_sample}`, severity: 'error' });
          return;
        }
      }
    }
    if (!amenity.amenidad_nocturna && timeRanges.to.value.isBefore(timeRanges.from.value)) {
      setSnackbar({ open: true, message: "La hora de finalizacion debe de ser mayor a la de inicio", severity: 'error' });
      return;
    }
    if (amenity.amenidad_nocturna && timeRanges.to.value.isAfter(timeRanges.from.value) && !timeRanges.to.value.isSame(timeRanges.from.value, 'day')) {
      setSnackbar({ open: true, message: "Para reservaciones nocturnas, la hora de finalización debe ser menor a la de inicio o en el mismo día", severity: 'error' });
      return;
    }
    if (timeRanges.to.value.isBefore(dayjs())) {
      setSnackbar({ open: true, message: "Los rangos de tiempo de la reserva ya pasaron ", severity: 'error' });
      return;
    }
    if (timeRanges.to.value.isSame(timeRanges.from.value)) {
      setSnackbar({ open: true, message: "La hora de inicio y final son las mismas", severity: 'error' });
      return;
    }
    closeAdminReservationDialog()
    setReservationDialog(true);
  }


  const handleDrawerToggle = () => {
    setDrawerShow(!drawerShow);
  }

  const handleDateChange = (newDate) => {
    setDate(newDate);
  }

  const handleTimeStartChange = (newTime) => {
    let timeRangesTemp = timeRanges;
    timeRangesTemp.from.value = newTime;
    setTimeRanges(timeRangesTemp);

  }

  const handleTimeEndChange = (newTime) => {
    let timeRangesTemp = {...timeRanges};
    timeRangesTemp.to.value = newTime;
    setTimeRanges(timeRangesTemp);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const ceil = (option, dayjsClass) => {
    dayjsClass.prototype.ceil = function (unit, amount) {
        return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
    };
  };
  dayjs.extend(ceil);

  const fetchReservations = (date,id,timeRangesLocal = timeRanges,amenityLocal = amenity) => {
    const formated_date = date.format('DDMMYY');
    const dayOfWeek = date.day() || 7;
    axios.get(`${apiUrl}/amenity/reservations/${formated_date}/${id}`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          let franjas = null;
          if (amenityLocal.servicio.length > 0) {
            amenityLocal.servicio.forEach(servicio => {
              if (servicio.dias.includes(dayOfWeek)) {
                franjas = servicio.franjas;
                return;
              } else if (servicio.dias.includes(0)) {
                franjas = servicio.franjas;
              }
            });
          }
          let dataArray =[]
          if (franjas) {
            franjas.forEach(franja => {
              const inicio_str = franja.split('-')[0];
              const fin_str = franja.split('-')[1];
              const inicio = date.set('hour', parseInt(inicio_str.split(':')[0])).set('minute', parseInt(inicio_str.split(':')[1])).set('second', 0);
              const fin = date.set('hour', parseInt(fin_str.split(':')[0])).set('minute', parseInt(fin_str.split(':')[1])).set('second', 0);
              const franja_id = `${amenityId}-${inicio.unix()}`;
              response.data.data[franja_id] = {"servicio": true, "confirmada": true,"inicio": inicio.unix(),"fin": fin.unix(), "inicio_uf": inicio_str, "fin_uf": fin_str};
            });
            dataArray = Object.entries(response.data.data).map(([id, value]) => ({ id, ...value }));
            dataArray.sort((a, b) => a.id.localeCompare(b.id));
          } else {
            dataArray = Object.entries(response.data.data).map(([id, value]) => ({ id, ...value }));
          }
          setAmenityReservations(dataArray);
        }
      })
      .catch(error => {
        console.error('Error fetching reservations:', error);
        setSnackbar({ open: true, message: 'Hubo un error obteniendo las reservaciones de la amenidad', severity: 'error' });
      });
  }

  const fetchInfo = (date,id) => {

    const dayOfWeek = date.day() || 7;
    axios.get(`${apiUrl}/amenity/${id}`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          let disponible_hoy = false;
          let amenidad_nocturna = false;
          let inicio = date;
          let inicio_uf = "00:00";
          let fin = date;
          let fin_uf = "23:59";
          let amenityTemp = response.data.data;
          amenityTemp.horario_predefinido = false;
          amenityTemp.dia_de_semana = weekDays[dayOfWeek-1]
          amenityTemp.mes = monthsSpa[date.month()];
          amenityTemp.showBlock = false;
          
          let timeRangesTemp = {"from": {"min":date,"max":date,"value":date}, "to": {"min":date,"max":date,"value": date}}
          for (const horario of response.data.data.horarios) {
            if (horario.dias.includes(dayOfWeek)) {
              disponible_hoy = true;
              inicio_uf = horario.hora_inicio;
              fin_uf = horario.hora_fin;
              inicio = date.set('hour', parseInt(horario.hora_inicio.split(':')[0])).set('minute', parseInt(horario.hora_inicio.split(':')[1])).set('second', 0);
              fin = date.set('hour', parseInt(horario.hora_fin.split(':')[0])).set('minute', parseInt(horario.hora_fin.split(':')[1])).set('second', 0);
              timeRangesTemp = {"from": {"min":inicio,"max":fin.subtract(amenityTemp.reservacion.tiempo_minimo-1,"minutes"),"value": inicio}, "to": {"min":inicio.add(amenityTemp.reservacion.tiempo_minimo - 1, "minutes"),"max":fin,"value":inicio.add(amenityTemp.reservacion.tiempo_minimo*2,"minutes")}};
              if (horario.hora_inicio > horario.hora_fin) {
                amenidad_nocturna = true;
                timeRangesTemp.from.max = date.set('hour', 23).set('minute', 59).set('second', 59);
              }
              break;
            }
          }
          if (response.data.data.reservacion.horario_predefinido) {
            amenityTemp.horario_predefinido = true;
            let from_all = date.set('hour', parseInt(response.data.data.reservacion.horario_predefinido.hora_inicio.split(':')[0])).set('minute', parseInt(response.data.data.reservacion.horario_predefinido.hora_inicio.split(':')[1])).set('second', 0);
            let to_all = date.set('hour', parseInt(response.data.data.reservacion.horario_predefinido.hora_fin.split(':')[0])).set('minute', parseInt(response.data.data.reservacion.horario_predefinido.hora_fin.split(':')[1])).set('second', 0);
            timeRangesTemp = {"from": {"min":from_all,"max":from_all,"value": from_all}, "to": {"min":to_all,"max":to_all,"value":to_all}};
          }
          amenityTemp.can_reserve = AuthService.userRoleIncludes(["habitante","admin"])
          if (disponible_hoy && amenityTemp.bloquear) {
            if (dayjs.unix(amenityTemp.bloquear.fecha_fin).isAfter(inicio)) {
              amenityTemp.showBlock = true;
            }
          }
          amenityTemp.amenidad_nocturna = amenidad_nocturna;
          setTimeRanges(timeRangesTemp);
          setAmenity({"disponible_hoy": disponible_hoy, "horario_hoy": {"inicio": inicio,"fin": fin, "inicio_uf":inicio_uf, "fin_uf": fin_uf},...response.data.data});
          fetchReservations(date,amenityId,timeRangesTemp,{"disponible_hoy": disponible_hoy, "horario_hoy": {"inicio": inicio,"fin": fin, "inicio_uf":inicio_uf, "fin_uf": fin_uf},...response.data.data});
          setShowBackdrop(false);
          setLoading(false);       
        }
      })
      .catch(error => {
        setRedirect({ trigger: true, route: '/amenidades' });
        console.error('Error fetching amenities:', error);
        setSnackbar({ open: true, message: 'Hubo un error obteniendo los detalles de la amenidad', severity: 'error' });
        setShowBackdrop(false);
      });
  }

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const reservationDrawer = (
    <Card
    sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px", width: "100%" }}>
        
      <CardContent>
        {amenity.reservacion && (
          <>
          {!amenity.horario_predefinido && (
            <Typography variant="body2" color="textSecondary" component="div" sx={{mt: -2, mb: 1}}>
              Tiempo maximo de reserva es de {timeToString(amenity.reservacion.tiempo_maximo)} *
            </Typography>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
            <DatePicker 
              sx={{ mt: 2, mb: 2, width: '100%' }}
              value={date}
              label="Dia"
              onChange={handleDateChange}
              disablePast={true}
              maxDate={dayjs().add(amenity.reservacion.max_dias_previos, 'day')}
              // closeOnSelect={true}
            />
            {amenity.horario_predefinido && (
              <Typography variant="body2" color="textSecondary" sx={{my: 1}}>
                Este horario esta predefinido por administracion y no puede ser modificado.
              </Typography>
            )}
            <TimePicker 
              label="Desde"
              value={timeRanges.from.value}
              sx={{my: 1}}
              minutesStep={15}
              onAccept={handleTimeStartChange}
              onChange={handleTimeStartChange}
              disabled={!amenity.disponible_hoy || amenity.horario_predefinido}
              minTime={timeRanges.from.min}
              maxTime={timeRanges.from.max}
            />
            <TimePicker
              label="Hasta"
              value={timeRanges.to.value}
              sx={{my: 1}}
              minutesStep={15}
              onAccept={handleTimeEndChange}
              onChange={handleTimeEndChange}
              disabled={!amenity.disponible_hoy || amenity.horario_predefinido}
              minTime={amenity.amenidad_nocturna ? null : timeRanges.to.min}
              maxTime={amenity.amenidad_nocturna ? null : timeRanges.to.max}
            />
            {amenity.amenidad_nocturna && (
              <Typography variant="body2" color="textSecondary" sx={{mt: 1, fontStyle: "italic"}}>
                * Esta es una reservación nocturna que termina al día siguiente.
              </Typography>
            )}
          </LocalizationProvider>
          
        {amenity.disponible_hoy && amenityReservations.length > 0 ? (
          <>
          <Divider sx={{my: 2}}/>
          <Typography variant="h6" component="div" sx={{mt: 1, mb: 1.5}}>
            Reservaciones del Dia
          </Typography>
          <Timeline position="left" sx={{ maxHeight: '30vh', overflow: 'auto' }}>
            <TimelineItem key="starting-time">
              <TimelineOppositeContent color="text.secondary">{amenity.horario_hoy.inicio_uf}</TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Disponible</TimelineContent>
            </TimelineItem>
            {amenityReservations.map((reservation, index) => (
              <TimelineItem key={reservation.id}>
                <TimelineOppositeContent color="text.secondary">
                  {AppConfig.formatHour(reservation.inicio)} a {AppConfig.formatHour(reservation.fin)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color={reservation.usuario === AuthService.getCurrentUserEmail() && reservation.confirmada ? "success" : "warning" } variant="outlined" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {reservation.servicio ? (
                    <>
                    Mantenimiento
                    <Typography variant="body2" color="text.secondary">
                      Servicio
                    </Typography>
                  </>
                    ):
                    (<>
                      {reservation.usuario === AuthService.getCurrentUserEmail() ? 'Tu Reservacion' : 'Reservado'}
                      <Typography variant="body2" color="text.secondary">
                          {reservation.confirmada ? 'Confirmada' : 'Pendiente'}
                      </Typography>
                    </>
                    )}
                  
                </TimelineContent>
              </TimelineItem>
            ))}
            <TimelineItem key="finishing-time">
              <TimelineOppositeContent color="text.secondary">{amenity.horario_hoy.fin_uf}</TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
              </TimelineSeparator>
              <TimelineContent>Cerrado</TimelineContent>
            </TimelineItem>
          </Timeline>
          </>
        ):
        ((
          <>
          {amenity.disponible_hoy ? (
          <Typography variant="h6" component="div" sx={{mt: 1, mb: 1.5}}>
            No hay ninguna reservacion en la fecha
          </Typography>
          
          ):
          (
            <Typography variant="h6" component="div" sx={{mt: 1, mb: 1.5}}>
              La amenidad no esta disponible en la fecha seleccionada
            </Typography>
          
          )}
          
          </>
        ))}
        {amenity.showBlock && (
          <Typography variant="body1" color="text.primary">
              Recuerda que esta amenidad esta bloqueada del {dayjs.unix(amenity.bloquear.fecha_inicio).format("DD/MM/YY HH:mm")} al {dayjs.unix(amenity.bloquear.fecha_fin).format("DD/MM/YY HH:mm")} por {amenity.bloquear.motivo}
          </Typography>
          )}
        </>)}
        {amenity.conflicto && (<Typography variant="h6" component="div" sx={{mt: 1, mb: 1.5}}>
            El rango de tiempo selecionado tiene conflicto con otra reservacion.
          </Typography>)}
      </CardContent>
      <CardActions>
        <Button size="medium" color="inherit" variant="outlined" onClick={handleDrawerToggle}>Cancelar</Button>
        <Button
          size="medium"
          color="secondary"
          variant="contained"
          onClick={() =>
            AuthService.getCurrentUserRole() === "admin"
              ? handleAdminReservation()
              : makeReservationPre()
          }
          disabled={amenity.conflicto}
        >
          Reservar
        </Button>
      </CardActions>
    </Card>
  );
  const generateTimeString = (horario) => {
    let timeString = '';

    // Function to group consecutive days
    const groupConsecutiveDays = (days) => {
      const groups = [];
      let currentGroup = [days[0]];
      days.sort((a, b) => a - b);
      for (let i = 1; i < days.length; i++) {
        if (days[i] - days[i-1] === 1) {
          currentGroup.push(days[i]);
        } else {
          groups.push(currentGroup);
          currentGroup = [days[i]];
        }
      }
      groups.push(currentGroup);
      return groups;
    };

    // Generate day range string
    const getDayRangeString = (group) => {
      if (group.length === 1) {
        return weekDays[group[0] - 1];
      } else if (group.length === 2) {
        return `${weekDays[group[0] - 1]} y ${weekDays[group[1] - 1]}`;
      } else {
        return `${weekDays[group[0] - 1]} a ${weekDays[group[group.length - 1] - 1]}`;
      }
    };

    if (horario.dias.length === 7) {
      timeString += 'Lunes a Domingo';
    } else {
      const groupedDays = groupConsecutiveDays(horario.dias);
      timeString += groupedDays.map(getDayRangeString).join(', ');
    }

    // Add time ranges
    if (horario.franjas && horario.franjas.length > 0) {
      timeString += ' de ';
      timeString += horario.franjas.join(' y ');
    }
    timeString += ` de ${horario.hora_inicio} a ${horario.hora_fin}`;
    return timeString.trim();
  };

  const handleAdminOptionsDrawerToggle = () => {
    setAdminOptionsDrawerOpen(!adminOptionsDrawerOpen);
  };

  const handleBlockAmenity = () => {
    setBlockModalOpen(true);
    setAdminOptionsDrawerOpen(false);
  };

  const handleBlockInputChange = (event) => {
    const { name, value } = event.target;
    setBlockData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlockSubmit = () => {
    setShowBackdrop(true);
    let ts = Math.floor(new Date().getTime() / 1000);
    if (new Date(blockData.fecha_fin).getTime() / 1000 < ts) {
      setSnackbar({ open: true, message: 'No es posible bloquear una fecha pasada', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    if (blockData.fecha_inicio > blockData.fecha_fin) {
      setSnackbar({ open: true, message: 'La fecha de inicio no puede ser mayor a la fecha de fin', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    axios.post(`${apiUrl}/amenity/${amenityId}/block`, {
        motivo: blockData.motivo,
        fecha_inicio: blockData.fecha_inicio ? new Date(blockData.fecha_inicio).getTime() / 1000 : null,
        fecha_fin: blockData.fecha_fin ? new Date(blockData.fecha_fin).getTime() / 1000 : null
    })
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: 'Amenidad bloqueada correctamente', severity: 'success' });
          fetchInfo(date, amenityId);
          setBlockModalOpen(false);
        }
      })
      .catch(error => {
        console.error('Error blocking amenity:', error);
        setSnackbar({ open: true, message: 'Error al bloquear la amenidad', severity: 'error' });
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const adminOptionsDrawer = (
    <Card
    sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px", width: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          Opciones de Administrador
        </Typography>
        <List>
          <ListItem 
            button 
            onClick={() => {
              setAdminOptionsDrawerOpen(false);
              handleDrawerToggle();
            }}
            disabled={amenity.reservacion && !amenity.reservacion.necesaria}
          >
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary="Reservar para un residente" secondary={amenity.reservacion && !amenity.reservacion.necesaria && "Esta amenidad no requiere reservaciones"} />
          </ListItem>
          <ListItem button onClick={() => {
            setAdminOptionsDrawerOpen(false);
            setRedirect({ trigger: true, route: `/amenityadmin?id=${amenityId}` });
          }}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Modificar esta amenidad" />
          </ListItem>
          <ListItem button disabled={amenity.reservacion && !amenity.reservacion.necesaria} onClick={handleBlockAmenity}>
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            <ListItemText primary="Bloquear esta amenidad" secondary={amenity.reservacion && !amenity.reservacion.necesaria && "Esta amenidad no requiere reservaciones"} />
          </ListItem>
          <ListItem button onClick={() => {
            setAdminOptionsDrawerOpen(false);
            setRedirect({ trigger: true, route: '/amenityadmin' });
          }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Administrar todas las amenidades" />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button size="medium" onClick={handleAdminOptionsDrawerToggle} color="inherit" variant="outlined">Cerrar</Button>
      </CardActions>
    </Card>
  );

  React.useEffect(() => {
    if (amenityId === null) {
      setRedirect({ trigger: true, route: '/amenidades' });
    }
    setShowBackdrop(true);
    setLoading(true);
    setNavbarState({ ...navbarState, showBack: true, showOptions: false, showSearch: false})
    fetchInfo(date,amenityId);
  }, [date,refresh]);

const container = window !== undefined ? () => window().document.body : undefined;
return (
    <>
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
    <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </Snackbar>
    <Box sx={{ mt: 1 }}>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
        {!loading && (
          <>
         <img src={`${apiUrl}/img/amenities/${amenityId}.jpg`} alt="Amenity Image" style={{ width: '100%', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', paddingLeft: 0}} />
          <Card
            key={amenityId}
            variant="outlined"
            sx={{ mt: -0.6, borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
          >
            <CardContent>
              <Typography variant="h5" component="div" sx={{mt: 1, mb: 1.5}}>
                <b>{amenity.nombre}</b>
              </Typography>
              <Typography variant="body1" color="text.primary">
                {amenity.descripcion} 
              </Typography>
              {amenity.cantidad > 1 && (
                <Typography variant="body1" color="text.secondary" sx={{my: 1, fontWeight: "bold"}}>
                  {amenity.cantidad} unidades disponibles
                </Typography>
              )}
              <Typography variant="h6" component="div" sx={{mt: 1}}>
                Horarios
              </Typography>
              
                <ul style={{ paddingLeft: "18px"}}>
                  {amenity.horarios.map((horario, index) => (
                    <li key={`horario-${index}`}>
                      <Typography variant="body1" color="text.primary">
                      {generateTimeString(horario)}
                      </Typography>
                    </li>
                  ))}
                </ul>
              {amenity.obs.length > 0 && (
                <>
                <Typography variant="h6" component="div">
                  Reglas y Observaciones
                </Typography>
                <ul style={{ paddingLeft: "18px"}}>
                  {amenity.obs.map((nota, index) => (
                    <li key={`nota-${index}`}>
                      <Typography variant="body1" color="text.primary">
                      {nota}
                      </Typography>
                    </li>
                  ))}
                </ul>
                </>
              )}
            
              {amenity.reservacion.necesaria ? (
                <>
                <Typography variant="h6" component="div">
                  Reservación
                </Typography>
                <ul style={{ paddingLeft: "18px"}}>
                  {amenity.amenidad_nocturna && (<li>
                    <Typography variant="body1" color="text.primary">
                      Esta amenidad es de uso nocturno.
                    </Typography>
                  </li>)}
                  {!amenity.horario_predefinido && (<li>
                    <Typography variant="body1" color="text.primary">
                      Tiempo maximo de reservacion {timeToString(amenity.reservacion.tiempo_maximo)}.
                    </Typography>
                  </li>)}
                  {amenity.reservacion.monto_pago > 0 && (<li>
                  <Typography variant="body1" color="text.primary">
                      Requiere pago de Q.{amenity.reservacion.monto_pago.toFixed(2)}
                    </Typography>
                  </li>)}
                  {amenity.showBlock && (<li>
                  <Typography variant="body1" color="text.primary">
                      Esta amenidad esta bloqueada del {dayjs.unix(amenity.bloquear.fecha_inicio).format("DD/MM/YY HH:mm")} al {dayjs.unix(amenity.bloquear.fecha_fin).format("DD/MM/YY HH:mm")} por {amenity.bloquear.motivo}
                    </Typography>
                  </li>)}
                </ul>
                </>
              ):
              (
                <>
                  <Typography variant="h6" component="div">
                    Uso Libre
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{mb: 2}}>
                    No requiere reservacion 
                  </Typography>
                </>
              )}
              
            </CardContent>
          </Card>
          </>)}
    </Box>
    {!loading && amenity.reservacion.necesaria && amenity.can_reserve  && (
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
        <Fab 
          sx={fabStyle} 
          variant="extended" 
          color="secondary" 
          size="large" 
          aria-label="options" 
          onClick={AuthService.userRoleIncludes(["admin", "superuser"]) ? handleAdminOptionsDrawerToggle : handleDrawerToggle}
        >
          {AuthService.userRoleIncludes(["admin", "superuser"]) ? (
            <>
              <MoreHorizIcon sx={{ mr: 1 }} />
              Mas Opciones
            </>
          ) : (
            <>
              <BookmarkAddIcon sx={{ mr: 1 }} />
              Reservar
            </>
          )}
        </Fab>
      </Box>
    )}
    {!loading && !amenity.reservacion.necesaria && (
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
        <Fab 
          sx={fabStyle} 
          variant="extended" 
          color="secondary" 
          size="large" 
          aria-label="options" 
          onClick={handleAdminOptionsDrawerToggle}
        >
          <MoreHorizIcon sx={{ mr: 1 }} />
          Mas Opciones
        </Fab>
      </Box>
    )}
    {!loading && (
    <>
    <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={drawerShow}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
          }}
        >
          {reservationDrawer}
    </Drawer>
    {!loading && (
    <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={adminOptionsDrawerOpen}
          onClose={handleAdminOptionsDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
          }}
        >
          {adminOptionsDrawer}
    </Drawer>
    )}
    <Dialog open={blockModalOpen} onClose={() => setBlockModalOpen(false)}>
        <DialogTitle>Bloquear {amenity.nombre}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="text.secondary">Durante el periodo seleccionado, la amenidad no estará disponible para los usuarios.</Typography>
          <TextField
            fullWidth
            label="Motivo"
            name="motivo"
            value={blockData.motivo}
            onChange={handleBlockInputChange}
            placeholder="Describe brevemente el motivo por el cual no se podria utilizar la amenidad"
            margin="normal"
            multiline
          />
          <TextField
            fullWidth
            label="Fecha de inicio"
            name="fecha_inicio"
            type="datetime-local"
            value={blockData.fecha_inicio}
            onChange={handleBlockInputChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: new Date().toISOString().slice(0, 16)
            }}
          />
          <TextField
            fullWidth
            label="Fecha de fin"
            name="fecha_fin"
            type="datetime-local"
            value={blockData.fecha_fin}
            onChange={handleBlockInputChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: new Date(blockData.fecha_inicio).toISOString().slice(0, 16)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBlockModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleBlockSubmit} color="secondary">Bloquear</Button>
        </DialogActions>
    </Dialog>
    <Dialog open={adminReservationDialog.show} onClose={closeAdminReservationDialog}>
        <DialogTitle>Datos de Residente</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre de Residente"
            value={adminReservationDialog.resident_name}
            onChange={(e) => setAdminReservationDialog({ ...adminReservationDialog, resident_name: e.target.value })}
            sx={{mb: 2, mt: 2}}
            fullWidth
          />
          <TextField
            label={AppConfig.getLocal('unit_type')}
            value={adminReservationDialog.resident_unit}
            placeholder={unit_sample}
            onChange={(e) => handleUnitChange(e)}
            fullWidth
            error={adminReservationDialog.error}
            helperText={adminReservationDialog.error_msg}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAdminReservationDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => makeReservationPre()} color="secondary" variant="contained">
            Continuar
          </Button>
        </DialogActions>
    </Dialog>
    <Dialog open={reservationDialog} onClose={closeReservationDialog}>
        <DialogTitle>Confirmar Reserva</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {reservationDialog ? <Typography>¿Está seguro que desea reservar esta amenidad el dia {amenity.dia_de_semana}, {date.format('DD')} de {amenity.mes} de {timeRanges.from.value.format('HH:mm')} a {timeRanges.to.value.format('HH:mm')} ?</Typography> : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeReservationDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => makeReservation()} color="secondary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
    </Dialog>
    </>
    )}
      
    <BackgroundImage />
    </Container>
  </>);
}